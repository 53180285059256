<template>
  <v-container>
    <div class="d-flex justify-end">
      <Button
        large
        :to="`ax/create`"
        icon="bi bi-plus-circle"
        tipo="primary"
        label="Novo"
      />
    </div>

    <v-row>
      <v-col v-for="ax in axis" :key="ax.id" cols="12">
        <AxisCard :card="ax.data()"></AxisCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AxisCard from "@/components/Cards/AxisCard";

export default {
  name: "AxisList",

  components: {
    AxisCard,
  },

  data: () => ({
    axis: [],
  }),

  computed: {
    domain() {
      return this.$route.params.domain.toUpperCase();
    },
  },

  async created() {
    const data = {
      collection: "axis",
      whe: "domain_code",
      where: this.domain,
      orderBy: "name",
    };
    const res = await this.$getDataWhere(data);
    res.forEach((doc) => {
      this.axis.push(doc);
    });
  },
};
</script>

<style scoped></style>
