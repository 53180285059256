import firebase from "firebase";
import Vue from "vue";

const db = firebase.firestore();

Vue.prototype.$database = firebase.firestore();

Vue.prototype.$getData = function (info) {
  return db
    .collection(info.collection)
    .limit(info.limit === undefined ? 20 : info.limit)
    .orderBy(info.orderBy === undefined ? "desc" : info.orderBy)
    .get()
    .then((querySnapshot) => {
      return querySnapshot.docs;
    });
};

Vue.prototype.$getDataWhere = function (info) {
  return db
    .collection(info.collection)
    .where(`${info.whe}`, "==", info.where)
    .orderBy(info.orderBy === undefined ? "desc" : info.orderBy)
    .get()
    .then((querySnapshot) => {
      return querySnapshot.docs;
    });
};

Vue.prototype.$getToken = async function () {
  const remoteConfig = firebase.remoteConfig();
  remoteConfig.settings = { minimumFetchIntervalMillis: 3600000 };
  await remoteConfig.fetchAndActivate();
  const val = await remoteConfig.getAll();
  sessionStorage.setItem("url", val.MEILI_MASTER_URL._value);
  sessionStorage.setItem("token", val.MEILI_MASTER_KEY._value);
  return val;
};
