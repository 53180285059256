<template>
  <v-navigation-drawer app clipped dark>
    <v-list shaped>
      <v-list-item
        v-for="item in menu[$route.params.domain]"
        :key="`/domain/${$route.params.domain}/${item.url}`"
        :to="`/domain/${$route.params.domain}/${item.url}`"
        :disabled="item.disabled"
        link
      >
        <v-list-item-content>
          <v-list-item-title class="medium-text bold">{{
            item.title
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
>
<script>
export default {
  data() {
    return {
      menu: {
        PORTUGUESE: [
          {
            title: "Questões",
            url: "qt",
            disabled: true,
          },
          {
            title: "Prova",
            url: "qz",
            disabled: true,
          },
          {
            title: "Atividades Digitais",
            url: "atividades_digitais",
            disabled: false,
          },
          {
            title: "Eixos",
            url: "ax",
            disabled: false,
          },
        ],
        MATH: [
          {
            title: "Questões",
            url: "qt",
            disabled: false,
          },
          {
            title: "Prova",
            url: "qz",
            disabled: true,
          },
          {
            title: "Atividades Digitais",
            url: "atividades_digitais",
            disabled: false,
          },
          {
            title: "Eixos",
            url: "ax",
            disabled: false,
          },
        ],
      },
    };
  },
};
</script>
