<template>
  <div>
    <v-row>
      <SelectField
        cols="3"
        v-on:change="search()"
        @click:clear="selectedStatus = null"
        @input="selectedStatus = $event"
        :fields="Status"
        :model="selectedStatus"
      />
      <SelectField
        cols="3"
        @input="eixoSelected = $event"
        :fields="Eixo"
        :model="eixoSelected"
        @HandleChange="filterEixo()"
      />

      <v-spacer></v-spacer>

      <Button
        tipo="primary"
        @ClickEvent="openPlanetList"
        label="Ordenar Planetas"
        class="mr-4"
      />
      <v-card class="ordem-list">
        <v-list dense v-if="planet" v-click-outside="openPlanetList">
          <v-subheader>REPORTS</v-subheader>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(item, index) in axis"
              :key="index"
              :to="{ name: 'da-sort', params: { axisId: item.id } }"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
      <Button
        tipo="primary"
        label="Novo"
        to="atividades_digitais/create"
        icon="bi bi-plus-circle"
      />
    </v-row>

    <v-row v-if="planets.length !== 0 && !loading">
      <v-col
        sm="6"
        md="6"
        lg="4"
        xl="3"
        v-for="(planet, i) in filtered ? filtered : planets"
        :key="i"
      >
        <PlanetCard
          class="ma-2"
          :planet="planet"
          @click="`/md/${planet.id}`"
        ></PlanetCard>
      </v-col>
      <v-col class="medium-text mt-12 text-center" v-if="filtered == 0">
        Nada encontrado
      </v-col>
    </v-row>
    <Skeleton v-if="loading" number="15" />
    <v-layout v-if="planets.length == 0 && selectedStatus">
      <v-flex xs6 class="d-flex justify-center">
        <v-card flat class="transparent text-center">
          <p class="mt-8 large semibold grey-300">Nada encontrado!</p>
          <v-img class="error-image" src="../../assets/lottie-notFound.json" />
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import firebase from "firebase";
import PlanetCard from "../../components/Cards/PlanetCard.vue";

export default {
  name: "PlanetList",
  components: {
    PlanetCard,
  },

  watch: {
    $route(to, from) {
      console.log(from, to);
    },
    selectedStatus() {
      this.search();
    },
  },

  data: () => ({
    planet: false,
    eixoSelected: "",
    filtered: null,
    loading: false,
    planets: [],
    axis: [],
    lastVisibleItem: null,
    Status: {
      label: "Status",
      clearable: true,
      answers: [
        "RASCUNHO",
        "PRODUÇÃO PEDAGÓGICO",
        "REVISÃO PEDAGÓGICA 1",
        "AJUSTE PÓS REVISÃO PEDAGÓGICA",
        "REVISÃO EXTERNA",
        "AJUSTE PÓS REVISÃO EXTERNA",
        "REVISÃO PEDAGÓGICA 2",
        "LIBERADO PEDAGÓGICO",
        "PRODUÇÃO AUDIOVISUAL",
        "LIBERADO AUDIOVISUAL",
        "VISTAS PEDAGÓGICO",
        "REVISÃO REJEITADA",
        "REVISÃO APROVADA",
        "TESTES E AJUSTES",
        "PRODUÇÃO",
        "ARQUIVADO",
      ],
    },
    Eixo: {
      label: "Eixos",
      clearable: true,
      answers: [],
      itemtxt: "name",
    },
    selectedStatus: null,
  }),

  computed: {
    domain() {
      return this.$route.params.domain.toUpperCase();
    },
  },

  methods: {
    openPlanetList() {
      this.planet = !this.planet;
    },
    async search() {
      const db = firebase.firestore();

      let query = db.collection("bundles");
      if (this.selectedStatus)
        query = query.where("status", "==", this.selectedStatus);

      await query
        .where("domain_code", "==", this.domain)
        .orderBy("title", "asc")
        .get()
        .then((querySnapshot) => {
          this.planets = [];
          querySnapshot.docs.forEach((doc) => {
            let data = doc.data();

            this.planets.push(data);
          });

          this.loading = false;
          this.lastVisibleItem =
            querySnapshot.docs[querySnapshot.docs.length - 1];
        });
      this.loading = false;
    },

    infiniteHandler($state) {
      const db = firebase.firestore();
      let query = db.collection("bundles");
      if (this.selectedStatus)
        query = query.where("status", "==", this.selectedStatus);

      query
        .where("domain_code", "==", this.domain)
        .startAfter(this.lastVisibleItem)
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((doc) => {
            this.planets.push(doc);
          });
          this.lastVisibleItem =
            querySnapshot.docs[querySnapshot.docs.length - 1];
          if (this.lastVisibleItem) {
            $state.loaded();
          } else {
            $state.complete();
          }
        });
    },

    async loadAxis() {
      const db = firebase.firestore();
      let allAxis = await db
        .collection("axis")
        .where("domain_code", "==", this.domain)
        .get();
      for (const ax of allAxis.docs) {
        let axis = ax.data();
        this.axis.push(axis);

        this.Eixo.answers.push({
          name: axis.name,
          value: axis.id,
        });
      }
    },

    filterEixo() {
      let axis = this.eixoSelected;
      if (axis) {
        let filt = [];
        this.planets.forEach((el) => {
          if (el.axis_id === axis) {
            filt.push(el);
          }
        });
        this.filtered = filt;
      } else {
        this.filtered = null;
      }
    },
  },

  created() {
    this.loading = true;
    this.loadAxis();
    this.search();
  },
};
</script>

<style scoped>
.sheet-border {
  border-radius: 25px !important;
}
.ordem-list {
  position: absolute;
  right: 350px;
  top: 80px;
  z-index: 10;
}
.error-image {
  width: 400px !important;
}
</style>
