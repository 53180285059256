<template>
    <v-dialog v-model="show" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="headline">{{ data.tag }}</span>
            </v-card-title>

            <v-card-text>
                <v-text-field
                        v-model="data.tag"
                        label="TAG"
                        outlined
                ></v-text-field>
                <v-select
                        v-model="data.domain_code"
                        :items="domains"
                        label="Domínio"
                        outlined
                        disabled
                ></v-select>
                <v-select
                        v-model="data.school_year"
                        :items="years"
                        label="Ano escolar"
                        outlined
                ></v-select>
                <v-select
                        v-model="data.level"
                        :items="levels"
                        label="Nível"
                        outlined
                ></v-select>
                <v-autocomplete
                        v-model="data.current_question_id"
                        :items="questions"
                        outlined
                        hide-no-data
                        item-text="title"
                        item-value="id"
                        label="Questão atual:"
                        placeholder="Comece a digitar para buscar">
                </v-autocomplete>
                <v-autocomplete
                        v-model="data.on_success"
                        :items="questions"
                        outlined
                        hide-no-data
                        item-text="title"
                        item-value="id"
                        label="Quando acertar:"
                        placeholder="Comece a digitar para buscar">
                </v-autocomplete>
                <v-autocomplete
                        v-model="data.on_fail"
                        :items="questions"
                        outlined
                        hide-no-data
                        item-text="title"
                        item-value="id"
                        label="Quando errar:"
                        placeholder="Comece a digitar para buscar">
                </v-autocomplete>
            </v-card-text>

            <v-card-actions>
                <v-btn color="red darken-1" v-if="id" text @click="exclude">Deletar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click.stop="show=false">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="save" :loading="saving">Salvar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import firebase from 'firebase';

    export default {
        name: "QuizFlowDialog",

        props: {
            showDialog: {
                type: Boolean,
                default: false
            },
            id: {
                type: String,
                default: null
            },
            questions: {
                type: Array,
                default: () => ([])
            }
        },

        data: () => ({
            loading: true,
            saving: false,

            domains: ['MATH', 'PORT'],
            levels: [0, 1, 2, 3, 4, 5],
            years: [0, 1, 2, 3, 4, 5],

            data: {
                id: null,
                tag: null,
                current_question_id: null,
                domain_code: "MATH",
                school_year: 0,
                level: 0,
                on_success: null,
                on_fail: null,
            },
        }),

        watch: {
            id() {
                if (this.id) {
                    this.loading = true;
                    let db = firebase.firestore();
                    let docRef = db.collection("quiz_flow").doc(this.id);
                    docRef.get().then((doc) => {
                        if (doc.exists) {
                            this.data = doc.data();
                            this.loading = false;
                        } else {
                            //TODO: Close this
                        }
                    });
                } else {
                    this.loading = false;
                }
            },
            showDialog() {
                if (!this.id) {
                    this.data = {
                        id: null,
                        tag: null,
                        current_question_id: null,
                        domain_code: 'MATH',
                        school_year: 0,
                        level: 0,
                        on_success: null,
                        on_fail: null,
                    }
                }
            }
        },

        computed: {

            show: {
                get() {
                    return this.showDialog;
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },

        methods: {

            exclude() {
                if (confirm('Meu Deus! 😱 Não tem volta!!! Tem certeza?')) {
                    this.saving = true;

                    let db = firebase.firestore();
                    let docRef = db.collection("quiz_flow").doc(this.id);
                    docRef.delete().then(() => {
                        this.$emit('update-quiz-flow', null)
                    }).finally(() => {
                        this.saving = false;
                    });
                }
            },

            save() {
                this.saving = true;

                this.data.updated_at = firebase.firestore.FieldValue.serverTimestamp();

                let db = firebase.firestore();
                let docRef = db.collection("quiz_flow");

                if (this.id) {
                    docRef.doc(this.id).set(this.data)
                        .then(() => {
                            this.$emit('update-quiz-flow', this.data)
                        })
                        .catch((error) => {
                            console.error("Error adding document: ", error);
                        }).finally(() => {
                        this.saving = false;
                    });
                } else {
                    const doc = docRef.doc();
                    this.data.id = doc.id;
                    doc.set(this.data).then(() => {
                        this.$emit('update-quiz-flow', this.data)
                    })
                        .catch((error) => {
                            console.error("Error adding document: ", error);
                        }).finally(() => {
                        this.saving = false;
                    });
                }
            }
        },
    }
</script>

<style scoped>

</style>