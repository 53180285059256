<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="show" max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formRule }}</span>
      </v-card-title>

      <v-card-text>
        <SelectField :fields="Tipo" :model="type" @input="type = $event" />
        <TextField label="Nome" :model="name" @input="name = $event" />
        <TextField
          label="Valor Padrão"
          :model="value"
          @input="value = $event"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.stop="show = false"
          >Cancelar</v-btn
        >
        <v-btn color="blue darken-1" text @click="save">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CardRuleDialog",

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    rule: {
      type: Object,
      default: null,
    },
  },

  computed: {
    formRule() {
      return this.rule == null ? "Nova Regra" : "Editar Regra";
    },

    show: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  data: () => ({
    loading: true,
    Tipo: {
      label: "Tipo",
      answers: ["String", "Boolean", "Int"],
    },
    types: ["STRING", "BOOLEAN", "INT"],
    value: "false",
    type: "BOOLEAN",
    name: "autoplay",
  }),
  mounted() {
    this.type = "BOOLEAN";
  },
  watch: {
    showDialog(val) {
      this.show = val;
    },
    rule(rule) {
      if (rule) {
        this.name = rule.name;
        this.type = rule.type;
        this.value = rule.value;
      }
    },
  },

  methods: {
    save() {
      if (this.rule) {
        this.rule.type = this.type;
        this.rule.name = this.name;
        this.rule.value = this.value;
        this.$emit("update-rule", this.rule);
      } else {
        this.$emit("create-rule", {
          type: this.type,
          name: this.name,
          value: this.value,
        });
      }

      this.type = "BOOLEAN";
      this.name = null;
      this.value = null;

      this.show = false;
    },
  },
};
</script>

<style scoped></style>
