<template>
  <div class="text-center" v-if="dialogData">
    <v-dialog v-model="dialog" width="700" persistent>
      <v-card width="700">
        <v-card-title class="text-h5 grey lighten-4">
          {{ dialogData.description }}
          <v-spacer></v-spacer>
          <span v-if="copiado" class="medium-text copy-text mr-2"
            >Copiado!
          </span>
          <v-btn icon @click="copy" class="mr-3">
            <v-icon :color="copiado === true ? 'green' : 'gray'" class="copy">
              mdi-content-copy</v-icon
            >
          </v-btn>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <div class="d-flex justify-center align-center pa-1">
          <video-player
            v-if="dialogData.type.toLowerCase() === 'video'"
            class="preview"
          >
            <source :src="dialogData.src" />
          </video-player>
          <v-img
            v-if="dialogData.type.toLowerCase() === 'image'"
            class="imagemodal"
            :src="dialogData.src"
          />
          <lottie-player
            v-if="
              dialogData.type.toLowerCase() === 'lottie' ||
              dialogData.type === 'Lottie'
            "
            :src="dialogData.src"
            background="transparent"
            class="preview path"
            loop
            autoplay
          >
          </lottie-player>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { videoPlayer, lottiePlayer } from "vue-md-player";
export default {
  props: ["dialogData", "dialog"],
  data() {
    return {
      copiado: false,
    };
  },
  components: { videoPlayer, lottiePlayer },
  methods: {
    close() {
      this.copiado = false;
      this.$emit("handleClose");
    },
    copy() {
      navigator.clipboard.writeText(this.dialogData.src);
      this.copiado = true;
    },
  },
  // watch: {
  //   dialogData() {
  //     console.log("dialogData", this.dialogData);
  //   },
  // },
};
</script>
<style scoped>
.imagemodal {
  width: 500px !important;
  height: 500px !important;
  object-fit: scale-down !important;
}
.copy {
  cursor: pointer;
}
.copy-text {
  color: green !important;
}
.preview {
  height: 500px;
}
</style>
