<template>
  <v-card outlined class="info-card" :to="`ax/${card.id}`">
    <v-layout no-gutters>
      <v-flex lg1 md2>
        <v-card-title>
          {{ card.code }}
        </v-card-title>
      </v-flex>
      <v-flex lg11 md10>
        <v-card-subtitle class="medium-text semibold">
          {{ card.name }}
        </v-card-subtitle>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "AxisCard",

  props: {
    card: {
      type: Object,
    },
  },
};
</script>

<style scoped></style>
