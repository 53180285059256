<template>
  <ais-instant-search
    :search-client="searchClient"
    :index-name="indicator.value"
  >
    <ais-stats>
      <template v-slot="{ nbHits }">
        <span class="normal gray-300"> </span>
        <v-card
          outlined
          flat
          max-width="215px"
          min-width="180px"
          class="ma-1 info-card"
        >
          <v-card-title class="medium-text">
            {{ nbHits | formatNumber }}
          </v-card-title>
          <v-card-subtitle class="medium-text">
            <span class="normal semibold">{{ indicator.label }} </span><br />
          </v-card-subtitle>
        </v-card>
      </template>
    </ais-stats>

    <ais-configure
      :filters="indicator.label === 'Planetas Pendentes' ? filters : ''"
    />
  </ais-instant-search>
</template>
<script>
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
export default {
  props: ["indicator"],
  data: () => ({
    searchClient: instantMeiliSearch(
      sessionStorage.getItem("url"),
      sessionStorage.getItem("token")
    ),
    filters: "status!=PRODUÇÃO AND status!=ARQUIVADO",
  }),
};
</script>
