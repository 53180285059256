<template>
  <v-app>
    <SideBar />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import SideBar from "../components/Bar/SideBar.vue";

export default {
  name: "DomainContainer",

  components: {
    SideBar,
  },

  mounted() {
      const { restricted } = this.user.data
      if (restricted) this.$router.push({ path: '/fl' });
  },

  computed: {
      ...mapGetters({
      user: "user",
      }),
  }
  
};
</script>
