<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container>
    <div class="d-flex justify-end mb-4">
      <v-btn
        large
        color="primary"
        :disabled="envs[1].disabled"
        @click="requestSync(envs[1])"
        rounded
        class="mr-4"
      >
        <v-icon left> mdi-sync </v-icon>
        Sincronizar ambiente de Produção
      </v-btn>

      <v-btn
        large
        color="primary"
        :disabled="envs[0].disabled"
        @click="requestSync(envs[0])"
        rounded
      >
        <v-icon left> mdi-sync </v-icon>
        Sincronizar ambiente de teste
      </v-btn>
    </div>

    <v-card outlined>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" rounded @click="handleClick">+</v-btn>
      </v-card-title>

      <v-data-table
        :loading="questionLoading"
        dense
        :headers="headers"
        :items="quizFlow"
        :search="search"
        :items-per-page="-1"
        hide-default-footer
        @click:row="handleClick"
      >
        <template v-slot:item.current_question_id="{ item }">
          {{ getQuestionTitle(item.current_question_id) }}
        </template>

        <template v-slot:item.on_success="{ item }">
          {{ getQuestionTitle(item.on_success) }}
        </template>

        <template v-slot:item.on_fail="{ item }">
          {{ getQuestionTitle(item.on_fail) }}
        </template>
      </v-data-table>
    </v-card>

    <QuizFlowDialog
      :showDialog="showQuizFlowDialog"
      :id="quizSelected"
      :questions="questions"
      v-model="showQuizFlowDialog"
      @update-quiz-flow="updateQuizFlow"
    ></QuizFlowDialog>
  </v-container>
</template>

<script>
import firebase from "firebase";
import QuizFlowDialog from ".././components/Pages/Quiz/QuizFlowDialog";

export default {
  name: "QuizFlow",
  components: { QuizFlowDialog },
  data: () => ({
    envs: [
      {
        name: "DEV",
        disabled: true,
      },
      {
        name: "PROD",
        disabled: true,
      },
    ],

    search: "",
    headers: [
      { text: "TAG", value: "tag" },
      { text: "Questão Origem", value: "current_question_id", sortable: false },
      { text: "No Sucesso", value: "on_success", sortable: false },
      { text: "Na Falha", value: "on_fail", sortable: false },
      { text: "Ano Escolar", value: "school_year" },
      { text: "Level", value: "level" },
    ],

    questionLoading: true,
    questionsDict: {},
    questions: [],
    axisDict: {},
    quizFlow: [],

    quizSelected: null,
    showQuizFlowDialog: false,
  }),

  methods: {
    requestSync(env) {
      let db = firebase.firestore();
      let docRef = db.collection("request_sync");
      const doc = docRef.doc();

      const request = {
        id: doc.id,
        env: env.name,
        domain_code: "MATH",
        req_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        status: "PENDING",
        userId: firebase.auth().currentUser.uid,
      };

      doc
        .set(request)
        .then(() => {
          env.disabled = true;
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },

    fetchRequestSync(env) {
      const db = firebase.firestore();
      db.collection("request_sync")
        .where("env", "==", env.name)
        .where("status", "==", "PENDING")
        .limit(1)
        .get()
        .then((querySnapshot) => {
          env.disabled = querySnapshot.docs.length > 0;
        });
    },

    fetchQuestions() {
      const db = firebase.firestore();

      db.collection("questions")
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((doc) => {
            const item = doc.data();
            this.questionsDict[doc.id] = item;
            this.questions.push(item);
          });
        });
    },

    fetchAxis() {
      const db = firebase.firestore();

      db.collection("axis")
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((doc) => {
            this.axisDict[doc.id] = doc.data();
          });
        });
    },

    fetchQuizFlow() {
      const db = firebase.firestore();

      db.collection("quiz_flow")
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((doc) => {
            this.quizFlow.push(doc.data());
          });
          this.questionLoading = false;
        });
    },

    getQuestionTitle(id) {
      if (id) {
        const question = this.questionsDict[id];
        const axis = this.axisDict[question.axis_id];
        return `${axis.code} | ${question.title}`;
      } else {
        return "Nulo";
      }
    },

    updateQuizFlow(item) {
      if (item) {
        let add = true;

        for (let [index, value] of this.quizFlow.entries()) {
          if (item.id === value.id) {
            this.quizFlow[index] = item;
            add = false;
            break;
          }
        }

        if (add) this.quizFlow.push(item);

        this.showQuizFlowDialog = false;
      } else {
        this.showQuizFlowDialog = false;
        this.$router.go(this.$router.currentRoute);
      }
    },

    handleClick(value) {
      this.showQuizFlowDialog = true;
      this.quizSelected = value ? value.id : null;
    },
  },

  created() {
    this.fetchRequestSync(this.envs[0]);
    this.fetchRequestSync(this.envs[1]);
    this.fetchAxis();
    this.fetchQuestions();
    this.fetchQuizFlow();
  },
};
</script>

<style scoped></style>
