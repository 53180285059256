import firebase from "firebase";

const App = {
  apiKey: "AIzaSyCbpsLWLREBAspwt5BFaATtZnCoSf68vpg",
  authDomain: "eduedulab.firebaseapp.com",
  databaseURL: "https://eduedulab.firebaseio.com",
  projectId: "eduedulab",
  storageBucket: "eduedulab.appspot.com",
  messagingSenderId: "1011046831657",
  appId: "1:1011046831657:web:3e50d2165aabb333ef2e38",
  measurementId: "G-MVBRK3YRRL",
};

const firebaseApp = firebase.initializeApp(App);
firebase.analytics();
export default firebaseApp;
