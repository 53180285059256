<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container>
    <v-card :loading="loading" outlined shaped>
      <v-row justify="center">
        <v-col cols="12">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card flat>
                  <div class="pa-4">
                    <div class="d-flex justify-space-between">
                      <h2 class="large semibold">
                        {{ QuestionData.action }}
                        {{
                          QuestionData.action === "Clonando Questão" ||
                          QuestionData.action === "Transferindo Questão"
                            ? ` do planeta ${QuestionData.planeta}`
                            : ""
                        }}
                      </h2>
                      <v-col class="d-flex justify-end">
                        <Button
                          :loading="saving"
                          :disabled="saving"
                          tipo="primary"
                          label="Salvar"
                          @ClickEvent="handleSave"
                        />
                      </v-col>
                      <!--                                        <div v-if="question.updated_at" class="text-overline">Atualizado em: {{-->
                      <!--                                                question.updated_at.toDate() }}-->
                      <!--                                        </div>-->
                    </div>
                    <div>
                      <p class="bold">
                        <a :href="`/domain/PORTUGUESE/atividades_digitais/${planetId}`">
                          Planeta: {{ this.bundleTitle }} planetId: {{ planetId }}
                        </a>
                      </p>
                    </div>
                    <v-form ref="form">
                      <v-row>
                        <TextField
                          cols="6"
                          label="Título"
                          :model="question.title"
                          @input="question.title = $event"
                          rules="true"
                        />
                        <SelectField
                          cols="6"
                          :model="question.status"
                          @input="question.status = $event"
                          :fields="Estado"
                        />

                        <TextField
                          cols="12"
                          label="Descrição"
                          :model="question.description"
                          @input="question.description = $event"
                          rules="true"
                        />
                        <v-col cols="12">
                          <v-autocomplete
                            dense
                            v-model="question.axis_id"
                            :items="axis"
                            outlined
                            hide-no-data
                            item-text="name"
                            :rules="[(v) => !!v || 'Campo Obrigatório']"
                            item-value="id"
                            label="Eixo:"
                            placeholder="Comece a digitar para buscar"
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-form>
                  </div>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card class="pa-4" outlined>
                  <h2 class="mb-4">Modelo</h2>
                  <Skeleton v-if="loadingModels" number="3" />
                  <v-slide-group
                    v-else
                    v-model="modelPosition"
                    class="pa-4"
                    center-active
                    show-arrows
                  >
                    <v-slide-item
                      v-for="model in models"
                      :key="model.id"
                      v-slot:default="{ active, toggle }"
                    >
                      <div>
                        <v-card
                          :img="model.url"
                          class="ma-4"
                          height="288"
                          width="432"
                          contain
                          @click="selectModel(model, toggle)"
                        >
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                            <v-scale-transition>
                              <v-icon
                                v-if="active"
                                color="primary"
                                size="48"
                                v-text="'mdi-checkbox-marked-circle'"
                              >
                              </v-icon>
                            </v-scale-transition>
                          </v-row>
                        </v-card>

                        <div align="center">
                          {{ model.id }}
                        </div>
                        <div align="center">
                          <v-btn
                            v-if="model.doc_link"
                            class="ma-2"
                            text
                            icon
                            :href="model.doc_link"
                            target="_blank"
                            color="blue lighten-2"
                          >
                            <v-icon>mdi-jira</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="model.video_link"
                            class="ma-2"
                            text
                            icon
                            :href="model.video_link"
                            target="_blank"
                            color="red lighten-2"
                          >
                            <v-icon>mdi-youtube</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </v-slide-item>
                  </v-slide-group>
                </v-card>

                <v-card
                  v-if="question.model_id != null"
                  class="mt-8 pa-4"
                  outlined
                >
                  <h2>Regras</h2>

                  <RuleForm
                    v-for="(rule, i) in question.rules"
                    :key="i"
                    :rule="rule"
                  >
                  </RuleForm>

                  <div class="d-flex justify-center">
                    <v-btn @click.stop="showRuleDialog = true" outlined>
                      Adicionar Regra
                    </v-btn>
                  </div>
                </v-card>

                <v-card
                  v-if="question.model_id != null"
                  class="mt-8 pa-4"
                  outlined
                >
                  <h2>Enunciados</h2>

                  <TitleForm
                    v-for="title in question.titles"
                    :key="title.id"
                    :title="title"
                  >
                    <div class="d-flex justify-end">
                      <v-btn
                        v-if="!title.required"
                        outlined
                        @click="deleteTitle(title)"
                      >
                        <v-icon> mdi-delete </v-icon>
                      </v-btn>
                    </div>
                  </TitleForm>

                  <div class="d-flex justify-center">
                    <v-btn @click.stop="showTitleDialog = true" outlined>
                      Adicionar Enunciado
                    </v-btn>
                  </div>
                </v-card>

                <v-card
                  v-if="question.model_id != null"
                  class="mt-8 pa-4"
                  outlined
                >
                  <h2>Alternativas</h2>

                  <OptionForm
                    v-for="option in question.options"
                    :key="option.id"
                    :option="option"
                  >
                    <div class="d-flex justify-end">
                      <v-btn outlined @click="deleteOption(option)">
                        <v-icon> mdi-delete </v-icon>
                      </v-btn>
                    </div>
                  </OptionForm>

                  <div class="d-flex justify-center">
                    <v-btn @click="addOption()" outlined>
                      Adicionar alternativa
                    </v-btn>
                  </div>
                </v-card>
              </v-col>

              <!--            <v-row>-->
              <!--                <v-col cols="12" class="d-flex justify-end">-->
              <!--                    <v-btn v-if="id != null" @click="deleteQuestion" dark color="red darken-3" :loading="saving"-->
              <!--                           :disabled="saving"-->
              <!--                           class="mr-2">Deletar-->
              <!--                    </v-btn>-->
              <!--                    <v-btn @click="save()" class="mr-2" color="primary" :loading="saving">Salvar</v-btn>-->
              <!--                </v-col>-->
              <!--            </v-row>-->
            </v-row>

            <CardTitleDialog
              @add-title="addTitle"
              :showDialog="showTitleDialog"
              v-model="showTitleDialog"
            >
            </CardTitleDialog>

            <CardRuleDialog
              @create-rule="createRule"
              :showDialog="showRuleDialog"
              v-model="showRuleDialog"
            >
            </CardRuleDialog>
          </v-container>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import firebase from "firebase";
import TitleForm from "../../components/Pages/Questions/TitleForm";
import RuleForm from "../../components/Pages/Questions/RuleForm";
import OptionForm from "../../components/Pages/Questions/OptionForm";
import CardTitleDialog from "../../components/Pages/Questions/CardTitleDialog";
import CardRuleDialog from "../../components/Dialogs/CardRuleDialog";

export default {
  name: "QuestionDialog",

  components: {
    TitleForm,
    RuleForm,
    OptionForm,
    CardTitleDialog,
    CardRuleDialog,
  },

  props: {
    id: {
      type: String,
      default: null,
    },
    planetId: {
      type: String,
    },
  },

  computed: {
    formTitle() {
      return this.id == null ? "Nova Questão" : `Editando ${this.id}`;
    },
  },

  data: () => ({
    loading: false,
    saving: false,
    loadingModels: false,
    QuestionData: {
      action: sessionStorage.getItem("params"),
    },
    axis: [],
    models: [],
    levels: [0, 1, 2, 3, 4, 5],
    Estado: {
      rules: true,
      label: "Status",
      answers: [
        "DESENVOLVIMENTO",
        "RASCUNHO",
        "PRODUÇÃO PEDAGÓGICO",
        "REVISÃO PEDAGÓGICA 1",
        "AJUSTE PÓS REVISÃO PEDAGÓGICA",
        "REVISÃO EXTERNA",
        "AJUSTE PÓS REVISÃO EXTERNA",
        "REVISÃO PEDAGÓGICA 2",
        "LIBERADO PEDAGÓGICO",
        "PRODUÇÃO AUDIOVISUAL",
        "LIBERADO AUDIOVISUAL",
        "VISTAS PEDAGÓGICO",
        "REVISÃO REJEITADA",
        "REVISÃO APROVADA",
        "TESTES E AJUSTES",
        "PRODUÇÃO",
        "ARQUIVADO",
      ],
    },
    question: {
      id: null,
      axis_id: null,
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      model_id: null,
      title: null,
      description: null,
      level: 0,
      position: 0,
      status: "DESENVOLVIMENTO",

      titles: [],
      options: [],
      rules: [],
    },

    modelPosition: null,
    modelSelected: null,

    showRuleDialog: false,
    showTitleDialog: false,

    bundleTitle: ""
  }),

  methods: {
    async loadModels() {
      const db = firebase.firestore();
      let allModels = await db
        .collection("models")
        // .where("status", "!=", "ARQUIVADO")
        // .orderBy("status")
        .orderBy("id")
        .get();

      this.models = [];

      for (const md of allModels.docs) {
        let model = md.data();

        if (model.status === "ARQUIVADO") {
          continue;
        }

        if (model.image) {
          let storageRef = firebase.storage().ref(model.image);
          model.url = await storageRef.getDownloadURL();
        }

        this.models.push(model);
      }

      //todo: sort models by id
    },

    selectModel(model, toggle) {
      if (
        this.question.model_id == null ||
        confirm(
          "Será preciso cadastrar enunciados e regras novamente. Tem certeza?"
        )
      ) {
        this.modelSelected = model;
        this.question.model_id = model.id;

        this.question.rules = [];
        this.question.titles = [];

        this.question.rules.push(...model.rules);
        this.question.titles.push(...model.titles);

        for (const [position, title] of this.question.titles.entries()) {
          title.file_id = null;
          title.position = position;
          title.placeholder = title.description;
        }

        toggle();
      }
    },

    addOption() {
      this.question.options.push({
        id: Math.floor(Math.random() * 1000),
        description: null,
        image_id: null,
        sound_id: null,
        isCorrect: false,
      });
    },

    addTitle(title) {
      this.question.titles.push(title);
    },

    deleteOption(option) {
      const index = this.question.options.indexOf(option);
      if (index > -1) {
        this.question.options.splice(index, 1);
      }
    },

    deleteTitle(position) {
      const index = this.question.titles.indexOf(position);
      if (index > -1) {
        this.question.titles.splice(index, 1);
      }
    },

    createRule(rule) {
      this.question.rules.push(rule);
    },

    deleteQuestion() {
      if (confirm("Meu Deus! 😱 Não tem volta!!! Tem certeza?")) {
        this.saving = true;

        let db = firebase.firestore();
        let docRef = db.collection("questions").doc(this.id);
        docRef.delete().then(() => {
          this.$router.push({ name: "question-list" });
        });
      }
    },

    async loadAxis() {
      const db = firebase.firestore();
      let allAxis = await db
        .collection("axis")
        .where(`domain_code`, "==", `${this.$route.params.domain}`)
        .get();
      for (const ax of allAxis.docs) {
        this.axis.push(ax.data());
      }
    },

    loadQuestion() {
      this.loadingModels = true;

      let db = firebase.firestore();
      let docRef = db.collection("questions").doc(this.id);
      docRef.get().then((doc) => {
        if (doc.exists) {
          let data = doc.data();

          this.question = data;
          data.options.forEach((el, i) => {
            el.id = Math.floor(Math.random() * 1000 + i);
          });

          data.titles.forEach((el, i) => {
            el.id = Math.floor(Math.random() * 1000 + i);
          });
          this.question = data;

          // for (let position in this.models) {
          //   const model = this.models[position];
          //   if (model.id === this.question.model_id) {
          //     this.modelPosition = parseInt(position);
          //     this.modelSelected = model;
          //     break;
          //   }
          // }

          this.loading = false;
        } else {
          this.$router.push({ name: "question-list" });
        }
      });
    },
    setModel() {
      if (this.id) {
        for (let position in this.models) {
          const model = this.models[position];

          if (model.id === this.question.model_id) {
            this.modelPosition = parseInt(position);
            this.modelSelected = model;
            this.loadingModels = false;
            break;
          }
        }
      } else {
        this.loadingModels = false;
      }
      this.loadingModels = false;
    },

    handleSave() {
      if (this.$refs.form.validate()) {
        this.question.updated_at =
          firebase.firestore.FieldValue.serverTimestamp();
        this.question.planet_id = this.planetId;

        let db = firebase.firestore();
        let docRef = db.collection("questions");

        if (
          this.QuestionData.action === "Editando Questão" ||
          this.QuestionData.action === "Transferindo Questão"
        ) {
          docRef
            .doc(this.id)
            .set(this.question)
            .then(() => {
              this.$router.push({
                name: "da-planet",
                params: { id: this.planetId },
              });
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
              this.saving = false;
            });
        } else {
          const doc = docRef.doc();
          this.question.id = doc.id;

          doc
            .set(this.question)
            .then(() => {
              this.$router.push({
                name: "da-planet",
                params: { id: this.planetId },
              });
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
              this.saving = false;
            });
        }
      }
    },
  },

  async mounted() {
    this.$refs.form.reset();
    if (this.id) {
      this.loadQuestion();
    } else {
      this.question = {
        id: null,
        updated_at: firebase.firestore.FieldValue.serverTimestamp(),
        model_id: null,
        title: null,
        description: null,
        level: 0,
        position: 0,
        status: "DESENVOLVIMENTO",

        titles: [],
        options: [],
        rules: [],
      };
    }
    firebase.firestore().collection("bundles").doc(this.planetId).get().then((planet) => {
      if (planet.exists) this.bundleTitle = planet.data().title
    })
    this.loadAxis();
    await this.loadModels();
    this.setModel();
  },
  watch: {
    QuestionData() {
      if (this.QuestionData.action) {
        sessionStorage.setItem("params", this.QuestionData.action);
        if (this.QuestionData.action === "Clonando Questão") {
          sessionStorage.setItem("planeta", this.QuestionData.planeta);
        } else {
          sessionStorage.removeItem("planeta");
        }
      }
    },
  },
  created() {
    const p = this.$route.params;
    const data = {
      action: p.action ? p.action : sessionStorage.getItem("params"),
      domain: p.domain,
      planeta: p.planeta ? p.planeta : sessionStorage.getItem("planeta"),
      id: p.id,
      planetId: p.planetId,
    };
    this.QuestionData = data;
  },
};
</script>

<style scoped></style>
