import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            dark: {
                primary: '#0F8FEF',
                secondary: '#006FD3',
                accent: '#FF6060',
            },
        },
        dark: false,
    },
});
