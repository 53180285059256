<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-card outlined class="pa-4 mb-2">
    <slot></slot>

    <v-row>
      <TextField
        cols="4"
        label="Ordem"
        persistent-hint
        outlined
        type="number"
        hint="Utilizado para organizar os parágrafos."
        :model="resume.position"
        @input="resume.position = $event"
        rules="true"
      />

      <SelectField
        cols="4"
        :fields="nivel"
        :model="resume.level"
        @input="resume.level = $event"
        rules
      />
      <SelectField
        cols="4"
        :fields="AnoEscolar"
        :model="resume.school_year"
        @input="resume.school_year = $event"
        rules
      />
      <v-col cols="12">
        <v-textarea
          label="Texto"
          v-model="resume.text"
          hint="Nome da criança: <b>%1$s</b> - negrito:<b>texto</b> - 4 espaços: &emsp; - quebrar linha: <br/>"
          persistent-hint
          required
          outlined
        ></v-textarea>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "ResultResumeForm",

  props: {
    resume: {
      type: Object,
      default: () => ({
        level: 0,
        position: 0,
        school_year: 0,
        axis_code: null,
        text: null,
        updated_at: null,
      }),
    },
  },

  data() {
    return {
      levels: [0, 1, 2, 3, 4, "IDEAL"],
      school_years: [0, 1, 2, 3, 4, 5],
      nivel: {
        label: "Nível",
        answers: [0, 1, 2, 3, 4, "IDEAL"],
      },
      AnoEscolar: {
        label: "Ano Escolar",
        answers: [0, 1, 2, 3, 4, 5],
      },
    };
  },
};
</script>

<style scoped></style>
