<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <ais-instant-search :search-client="searchClient" index-name="assets">
      <v-card outlined class="mb-2">
        <v-card-title class="d-flex">
          <p class="medium">
            Enunciado do tipo <span class="bold">{{ title.type }} </span>
          </p>
          <v-spacer></v-spacer>
          <slot></slot>
        </v-card-title>
        <v-row class="pl-3 pr-3">
          <v-col cols="6">
            <ais-autocomplete v-click-outside="onClickOutsideImage">
              <template v-slot="{ currentRefinement, refine, indices }">
                <v-card flat class="mt-2">
                  <div class="d-flex">
                    <input
                      :focus="`this.value=''`"
                      type="search"
                      class="fields"
                      :value="
                        imageAuto === true ? currentRefinement : description
                      "
                      @click="imageAuto = true"
                      placeholder="Buscar arquivo"
                      @input="refine($event.currentTarget.value)"
                    />
                    <v-icon
                      v-if="title.type.toUpperCase() !== 'AUDIO'"
                      @click="handleOpenFile()"
                      class="ml-1"
                    >
                      mdi-eye
                    </v-icon>
                    <v-icon
                      color="blue"
                      v-if="title.type.toUpperCase() === 'AUDIO'"
                      @click="playAudio()"
                    >
                      mdi-play
                    </v-icon>
                    <v-icon color="red" @click="removeItem()" class="ml-1">
                      mdi-close
                    </v-icon>
                  </div>
                  <v-card
                    class="card-details"
                    v-if="currentRefinement && imageAuto === true"
                  >
                    <ul v-for="index in indices" :key="index.indexId">
                      <li
                        class="mb-2 list-picker pa-2 d-flex"
                        v-for="hit in index.hits"
                        :key="hit.objectID"
                      >
                        <ais-highlight attribute="description" :hit="hit" />

                        <v-spacer></v-spacer>
                        <v-btn icon small @click="handleImageSelected(hit)">
                          <v-icon color="success" small
                            >mdi-check-circle-outline</v-icon
                          >
                        </v-btn>
                        <v-btn
                          icon
                          small
                          @click="playAudio(hit)"
                          v-if="hit.type === 'AUDIO'"
                        >
                          <v-icon color="blue" small
                            >mdi-play-circle-outline</v-icon
                          >
                        </v-btn>
                        <v-btn
                          icon
                          small
                          @click="handleOpenFile(hit)"
                          v-if="hit.type !== 'AUDIO'"
                        >
                          <v-icon color="blue" small>mdi-eye-outline</v-icon>
                        </v-btn>
                      </li>
                    </ul>
                  </v-card>
                </v-card>
              </template>
            </ais-autocomplete>
          </v-col>

          <ais-configure
            :attributesToSnippet="['description:50']"
            snippetEllipsisText="…"
            :hits-per-page.camel="5"
            :filters="`type=${title.type}`"
          />
          <v-col cols="6">
            <TextField
              :model="title.description"
              label="Descrição"
              @input="title.description = $event"
              :hint="title.placeholder"
              rules="true"
            />
          </v-col>
        </v-row>
      </v-card>
      <ModalImg
        :dialogData="dialogData"
        :dialog="dialog"
        @handleClose="handleClose"
      />
    </ais-instant-search>
  </div>
</template>

<script>
import ModalImg from "../../Dialogs/ImageDialog.vue";
import firebase from "firebase";
let db = null;
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
export default {
  name: "TitleForm",
  props: {
    title: {
      type: Object,
      default: () => ({
        file_id: null,
        description: null,
        type: "TEXT",
        position: 0,
        required: true,
        placeholder: null,
      }),
    },
  },
  components: { ModalImg },
  computed: {
    supportFile() {
      return !["TEXT", "REF"].includes(this.title.type);
    },
  },
  data() {
    return {
      imageAuto: false,
      description: "",
      searchClient: instantMeiliSearch(
        sessionStorage.getItem("url"),
        sessionStorage.getItem("token")
      ),
      File: {
        label: "Arquivo",
        answers: [],
        itemtxt: "description",
      },
      isLoading: false,
      dialogData: null,
      dialog: false,
      search: null,
      files: [],
      file_src: null,
    };
  },
  watch: {
    search(val) {
      if (!val) return;

      this.isLoading = true;

      let query = db
        .collection("assets")
        .where("searchTags", "array-contains-any", val.split(" "))
        .where("type", "==", this.title.type)
        .orderBy("updated_at", "desc")
        .limit(15);

      query.get().then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          let file = doc.data();
          const storageRef = firebase.storage().ref(file.url);
          storageRef.getDownloadURL().then((url) => {
            file.src = url;
            this.File.answers.push(file);
            this.files.push(file);
          });
        });
        this.isLoading = false;
      });
    },
    title() {
      console.log(this.title);
    },
  },

  methods: {
    handleImageSelected(e) {
      this.imageAuto = false;
      (this.title.file_id = e.id), (this.description = e.description);

      console.log(e);
    },
    removeItem() {
      this.description = null;
      this.title.file_id = null;
      console.log(this.title);
    },
    onClickOutsideImage() {
      if (this.imageAuto === true) {
        this.imageAuto = false;
      }
    },
    playAudio(hit) {
      if (this.title.file_id) {
        const storageRef = firebase
          .storage()
          .ref(
            `assets/${
              hit ? hit.id : this.title.file_id.id || this.title.file_id
            }`
          );
        storageRef.getDownloadURL().then((url) => {
          var audio = new Audio(url);
          audio.play();
        });
      }
    },
    async handleOpenFile(e) {
      if (!e) {
        const storageRef = firebase
          .storage()
          .ref(`assets/${this.title.file_id}`);
        await storageRef.getDownloadURL().then((url) => {
          this.dialogData = {
            description: null,
            type: this.title.type,
            src: url,
          };
        });
      } else if (e) {
        const storageRef = firebase.storage().ref(e.url);
        await storageRef.getDownloadURL().then((url) => {
          this.dialogData = {
            description: e.description,
            type: e.type,
            src: url,
          };
        });
      }
      if (this.dialogData) {
        this.dialog = true;
      }

      // this.$emit("openImg", item);
    },
    handleClose() {
      (this.dialogData = false), (this.dialogData = null);
    },
    fileUpdated() {
      const storageRef = firebase.storage().ref(`assets/${this.title.file_id}`);
      storageRef.getDownloadURL().then((url) => {
        this.file_src = url;
        console.log(this.file_src);
      });
    },
  },

  created() {
    db = firebase.firestore();
    if (this.title.file_id) {
      this.isLoading = true;
      let query = db
        .collection("assets")
        .doc(this.title.file_id.id || this.title.file_id);
      query
        .get()
        .then((doc) => {
          this.files = [];
          let data = doc.data();
          this.description = data.description;

          if (doc.exists) {
            let file = doc.data();

            const storageRef = firebase.storage().ref(file.url);
            storageRef.getDownloadURL().then((url) => {
              file.src = url;
              this.file_src = url;
              this.files.push(file);
            });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }

          this.isLoading = false;
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  },
};
</script>

<style scoped>
.card-details {
  position: absolute;
  z-index: 100;
  width: 100%;
}
.fields {
  width: 100% !important;
  border-radius: 5px !important;
  height: 40px !important;
}
.side-btn {
  width: 20px !important;
}
</style>
