const state = {
  axis: [],
};
const getters = {
  axisByDomain: (state, domain) => {
    return state.axis.filter((axis) => axis.domain_code === domain);
  },
  doubleCount(state) {
    return state.count * 2;
  },
};
const mutations = {};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
