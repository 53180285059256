<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container>
    <v-card :loading="loading" outlined shaped class="mb-4">
      <v-card-title class="large">
        {{ cardTitle }}
        <v-spacer></v-spacer>
        <div v-if="axis.updated_at" class="medium-text light">
          Atualizado em: {{ axis.updated_at | formatDate }}
        </div>
      </v-card-title>
      <v-row>
        <v-col cols="12">
          <v-card-text>
            <span class="medium-text"> Geral </span>
            <v-row>
              <v-col cols="4" class="d-flex justify-center">
                <v-color-picker
                  v-model="axis.color_hex"
                  swatches-max-height="200"
                  mode="hexa"
                  show-swatches
                >
                </v-color-picker>
              </v-col>
              <v-col cols="8">
                <v-form ref="form">
                  <v-row class="pa-4">
                    <TextField
                      cols="6"
                      :model="axis.code"
                      @input="axis.code = value"
                      label="Código"
                      rules="true"
                    />

                    <SelectField
                      :fields="Dominio"
                      rules
                      :model="axis.domain_code"
                      @input="domains = $event"
                      cols="6"
                    />

                    <TextField
                      rules="true"
                      cols="6"
                      :model="axis.name"
                      @input="axis.name = value"
                      label="Nome"
                    />
                    <SelectField
                      :fields="Status"
                      rules
                      :model="axis.status"
                      @input="axis.status = $event"
                      cols="6"
                    />
                  </v-row>
                </v-form>
                <v-textarea
                  class="pr-4 pl-4"
                  v-model="axis.description"
                  label="Descrição"
                  outlined
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>

    <v-card :loading="loading" outlined shaped>
      <v-card-title class="large"> Resumos </v-card-title>
      <v-row>
        <v-col cols="12">
          <v-card-text>
            <ResultResumeForm
              v-for="(resume, i) in resumes"
              :key="i"
              :resume="resume"
            >
              <div class="d-flex justify-end">
                <v-btn outlined @click="deleteResume(resume)">
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </div>
            </ResultResumeForm>
          </v-card-text>
        </v-col>
        <v-col>
          <div class="d-flex justify-center">
            <v-btn @click="addResume()" outlined> Adicionar Resumo </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <div>
      <v-col class="d-flex justify-end">
        <Button
          v-if="id != null"
          label="Deletar"
          tipo="error"
          @ClickEvent="deleteAxis"
          :disabled="saving"
          class="mr-4"
        />
        <Button
          label="Salvar"
          tipo="primary"
          @ClickEvent="save"
          :loading="saving"
          :disabled="saving"
        />
      </v-col>
    </div>
  </v-container>
</template>

<script>
import firebase from "firebase";
import ResultResumeForm from "../../components/Pages/Axis/ResultResumeForm";

export default {
  name: "AxisCreate",

  components: { ResultResumeForm },

  props: {
    id: {
      type: String,
      default: null,
    },
  },

  computed: {
    cardTitle() {
      return this.id == null ? "Novo Eixo" : "Editar Eixo";
    },
  },

  data: () => ({
    loading: true,
    saving: false,
    color_hex: null,

    axis: {
      id: null,
      code: null,
      name: null,
      description: null,
      color_hex: null,
      position: 0,
      domain_code: "MATH",
      status: "DESENVOLVIMENTO",
      updated_at: null,
    },

    status: ["RASCUNHO", "DESENVOLVIMENTO", "PRODUÇÃO", "ARQUIVADO"],
    domains: ["MATH", "PT"],
    Status: {
      label: "Status",
      answers: ["RASCUNHO", "DESENVOLVIMENTO", "PRODUÇÃO", "ARQUIVADO"],
    },
    Dominio: {
      label: "Domínio",
      answers: ["MATH", "PT"],
    },

    resumes: [],
  }),

  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.saving = true;

        this.axis.updated_at = firebase.firestore.FieldValue.serverTimestamp();

        if (this.axis.color_hex.hex) {
          this.axis.color_hex = this.axis.color_hex.hex;
        }

        let db = firebase.firestore();
        let docRef = db.collection("axis");

        if (this.id) {
          docRef
            .doc(this.id)
            .set(this.axis)
            .then(() => {
              this.saveResumes();
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
              this.saving = false;
            });
        } else {
          const doc = docRef.doc();
          this.axis.id = doc.id;
          doc
            .set(this.axis)
            .then(() => {
              this.saveResumes();
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
              this.saving = false;
            });
        }
      }
    },

    saveResumes() {
      let db = firebase.firestore();
      const batch = db.batch();

      this.resumes.forEach((obj) => {
        obj.axis_id = this.axis.id;
        obj.updated_at = firebase.firestore.FieldValue.serverTimestamp();
        let docRef = db.collection("axis_resumes");

        if (obj.id) {
          docRef.doc(obj.id).set(obj);
        } else {
          const doc = docRef.doc();
          obj.id = doc.id;
          doc.set(obj);
        }
      });

      batch
        .commit()
        .then(() => {
          this.$router.push({ name: "axis-list" });
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          this.saving = false;
        });
    },

    deleteAxis() {
      if (confirm("Meu Deus! 😱 Não tem volta!!! Tem certeza?")) {
        this.saving = true;

        let db = firebase.firestore();
        let docRef = db.collection("axis").doc(this.axis.id);
        docRef.delete().then(() => {
          this.$router.push({ name: "axis-list" });
        });
      }
    },

    addResume() {
      this.resumes.push({
        level: 0,
        position: 0,
        school_year: 0,
        text: null,
        updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      });
    },

    deleteResume(resume) {
      if (confirm("Tem certeza? É uma ação permanente!")) {
        const index = this.resumes.indexOf(resume);
        if (resume.id) {
          let db = firebase.firestore();
          let docRef = db.collection("axis_resumes").doc(resume.id);
          docRef.delete().then(() => {
            this.resumes.splice(index, 1);
          });
        } else {
          this.resumes.splice(index, 1);
        }
      }
    },

    loadResumes(axisId) {
      let db = firebase.firestore();
      db.collection("axis_resumes")
        .where("axis_id", "==", axisId)
        .orderBy("position", "asc")
        .orderBy("level", "asc")
        .orderBy("school_year", "asc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((doc) => {
            this.resumes.push(doc.data());
          });
          this.loading = false;
        });
    },
  },

  created() {
    if (this.id) {
      let db = firebase.firestore();
      let docRef = db.collection("axis").doc(this.id);
      docRef.get().then((doc) => {
        if (doc.exists) {
          this.axis = doc.data();
          this.loadResumes(this.axis.id);
        } else {
          this.$router.push({ name: "axis-list" });
        }
      });
    } else {
      this.loading = false;
    }
  },
};
</script>

<style scoped></style>
