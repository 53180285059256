<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="show" max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-form ref="form">
        <SelectField
          class="mt-3"
          :fields="Tipo"
          :model="type"
          @input="type = $event"
        />
        <TextField
          :model="description"
          @input="description = $event"
          label="Descrição"
          rules="true"
        />
        <v-checkbox
          class="ml-3"
          v-model="required"
          label="Obrigatório"
        ></v-checkbox>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>

        <Button label="Cancelar" @ClickEvent="show = false" tipo="error" />
        <Button label="Salvar" @ClickEvent="save" tipo="primary" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CardTitleDialog",

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: Object,
      default: null,
    },
  },

  computed: {
    formTitle() {
      return this.title == null ? "Novo enunciado" : "Editar Enunciado";
    },

    show: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  data: () => ({
    loading: true,
    Tipo: {
      label: "Tipo",
      answers: ["Vídeo", "Audio", "Image", "Text", "Lottie", "Ref"],
      rules: true,
    },
    required: false,
    type: "Audio",
    description: null,
    id: null,
  }),

  watch: {
    showDialog(val) {
      this.show = val;
    },
    title(title) {
      if (title) {
        this.id = title.id;
        this.description = title.description;
        this.type = title.type;
        this.required = title.required;
      }
    },
  },

  methods: {
    save() {
      if (this.$refs.form.validate()) {
        if (this.title) {
          this.title.type = this.type;
          this.title.description = this.description;
          this.title.required = this.required;
          this.$emit("update-title", this.title);
        } else {
          this.$emit("create-title", {
            id: Math.floor(Math.random() * 1000),
            type: this.type,
            description: this.description,
            required: this.required,
          });
        }

        this.required = false;
        this.type = "AUDIO";
        this.description = null;

        this.show = false;
      }
    },
  },
};
</script>

<style scoped></style>
