<template>
  <v-card outlined class="info-card">
    <v-img class="image" contain :src="src"> </v-img>

    <v-card-title class="large">
      {{ model.id }}
    </v-card-title>
    <v-card-subtitle class="subtitle normal light">
      {{ model.description ? model.description.substring(0, 100) : "" }}
    </v-card-subtitle>

    <v-card-actions>
      <Button
        icon="mdi-jira"
        tipo="icon"
        iconColor="primary"
        v-if="model.doc_link"
        :href="model.doc_link"
        target="_blank"
      />
      <Button
        icon="mdi-youtube"
        tipo="icon"
        iconColor="error"
        v-if="model.video_link"
        :href="model.video_link"
        target="_blank"
      />

      <v-spacer></v-spacer>

      <v-btn color="blue" text :to="`/md/${model.id}`"> EDITAR </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import firebase from "firebase";

export default {
  name: "ModelCard",

  props: {
    model: {
      type: Object,
    },
  },

  data: () => ({
    src: null,
  }),

  created() {
    if (!this.model.image) return;
    var storageRef = firebase.storage().ref(this.model.image);
    storageRef.getDownloadURL().then((url) => {
      this.src = url;
    });
  },
};
</script>

<style scoped>
.info-card {
  height: 470px;
}
.image {
  height: 270px;
}
.subtitle {
  height: 90px;
  text-justify: center;
}
</style>
