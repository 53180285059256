<template>
  <v-app class="transparent">
    <v-row class="pl-lg-12 pr-lg-12">
      <v-col cols="8">
        <v-layout wrap row>
          <v-flex v-for="(i, index) in indicators" :key="index">
            <IndicatorsCard :indicator="i" />
          </v-flex>
          <v-flex lg12 class="mt-2">
            <SimpleTable
              class="ma-1"
              :loading="loading"
              :headers="headers"
              :items="questions"
              @ActionBtn="open"
            />
            <infinite-loading
              v-if="questions.length > 20"
              @infinite="infiniteHandler"
              spinner="waveDots"
            >
              <div slot="no-more">
                <div class="text-h3 mt-4">🙆‍♂️</div>
                <div>Você chegou ao fim.</div>
              </div>
            </infinite-loading>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col cols="4">
        <PlanetCard
          v-for="planet in planets"
          :key="planet.id"
          :planet="planet.data()"
          class="mb-4"
        />
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import PlanetCard from "../components/Cards/PlanetCard.vue";
import firebase from "firebase";
import IndicatorsCard from "../components/Cards/IndicatorsCard.vue";
import { mapGetters } from 'vuex'

export default {
  name: "Dashboard",
  components: { PlanetCard, IndicatorsCard },
  data() {
    return {
      planets: [],
      questions: [],
      indicators: [
        { label: "Planetas Pendentes", value: "bundles" },
        { label: "Planetas", value: "bundles" },
        { label: "Questões", value: "questions" },
        { label: "Arquivos", value: "assets" },
      ],
      lastVisibleItem: null,
      loading: false,
      headers: [
        { text: "Título", value: "title", align: "center", sortable: false },
        {
          text: "Data",
          width: "25%",
          value: "updated_at",
          align: "center",
          sortable: false,
        },
        { text: "Status", value: "status", align: "center", sortable: false },
        {
          text: "",
          width: "15%",
          value: "action",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    loadQuestions() {
      this.$database
        .collection("questions")
        .limit(25)
        .orderBy("updated_at", "desc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((doc) => {
            this.questions.push(doc.data());
          });
          this.loading = false;
        });
    },

    async lastPlanets() {
      this.$database
        .collection("bundles")
        .limit(25)
        .orderBy("updated_at", "desc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((doc) => {
            this.planets.push(doc);
          });
          this.lastVisibleItem =
            querySnapshot.docs[querySnapshot.docs.length - 1];
          this.loading = false;
        });
    },

    open(item) {
      this.$router.push({
        name: "da-question-edit",
        params: {
          id: item.id,
          domain: null,
          planetId: item.planet_id,
        },
      });
    },

    infiniteHandler($state) {
      const db = firebase.firestore();
      db.collection("questions")
        .orderBy("updated_at", "desc")
        .startAfter(this.lastVisibleItem)
        .limit(25)
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((doc) => {
            this.questions.push(doc.data());
          });
          this.lastVisibleItem =
            querySnapshot.docs[querySnapshot.docs.length - 1];
          if (this.lastVisibleItem) {
            $state.loaded();
          } else {
            $state.complete();
          }
        });
    },
  },

  mounted() {
    this.loading = true;
    this.lastPlanets();
    this.loadQuestions();

    const { restricted } = this.user.data
    if (restricted) this.$router.push({ path: '/fl' });
    
  },

  computed: {
        ...mapGetters({
        user: "user",
        }),
    }
};
</script>

<style scoped>
tbody:hover {
  cursor: pointer;
}
</style>
