import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import VueFileAgent from "./plugins/VueFileAgent";
import InfiniteLoading from "./plugins/infiniteLoading";
import "./Global";
import firebase from "firebase";
import "./Services/FirebaseConfig";
import "./Services/Service";
import InstantSearch from "vue-instantsearch";
import dateformat from "dateformat";
import VueMask from "v-mask";

Vue.use(VueMask);
Vue.use(InstantSearch);
Vue.config.productionTip = false;

Vue.filter("formatNumber", function (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

Vue.filter("formatDate", function (value) {
  if (value) {
    return value.toDate().toDateString();
  }
});
Vue.filter("formatDatePay", function (value) {
  if (value) {
    return dateformat(new Date(value), "dd/mm/yyyy");
  }
});

Vue.filter("formatBytes", function (bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
});

new Vue({
  router,
  vuetify,
  store,
  VueFileAgent,
  InfiniteLoading,
  created() {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const db = firebase.firestore();

        const collection = db.collection("restricted_access");
        const query = collection.where("email", "==", user.email);
        const result = await query.get();
        const isUserRestricted = !result.empty;

        const developerAccess = await db
          .collection("developer_access")
          .where("email", "==", user.email)
          .get();

        const isDeveloper = !developerAccess.empty;

        if (
          user.email.endsWith("@institutoabcd.org.br") ||
          isUserRestricted ||
          isDeveloper
        ) {
          const usr = { ...user, restricted: isUserRestricted };

          await this.$getToken();
          store.dispatch("fetchUser", usr);
        } else {
          firebase.auth().signOut();
          this.$store.commit("CLEAN_USER_DATA");
        }
      } else {
        firebase.auth().signOut();
        this.$store.commit("CLEAN_USER_DATA");
      }
    });
  },
  render: (h) => h(App),
}).$mount("#app");
