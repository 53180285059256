<template>
  <div>
    <v-layout
      wrap
      class="grey-lighten-4 pa-6 grey lighten-5"
      justify-center
      v-if="Details"
    >
      <v-flex xs10>
        <Button
          @ClickEvent="goBack()"
          tipo="text"
          icon="mdi-arrow-left"
          iconColor="primary"
        />
      </v-flex>

      <v-flex sm12 class="d-flex justify-center">
        <v-card
          v-if="Details && Details[0]"
          :loading="loader"
          flat
          outlined
          class="rounded-xl image-cardy"
          justify="center"
        >
          <v-img
            v-if="Details[0].type !== 'VIDEO' && Details[0].type !== 'LOTTIE'"
            class="imag"
            :src="
              Details && Details[0].type === 'AUDIO'
                ? 'https://png.pngtree.com/png-vector/20190328/ourmid/pngtree-speaker-icon-design--essential-icon-vector-design-png-image_877696.jpg'
                : src
            "
          />
          <audio-player v-if="src && Details && Details[0].type === 'AUDIO'">
            <source :src="src" />
          </audio-player>

          <video-player
            class="imag"
            v-if="src && Details && Details[0].type === 'VIDEO'"
          >
            <source :src="src" />
          </video-player>
          <lottie-player
            v-if="src && Details && Details[0].type === 'LOTTIE'"
            :src="src"
            background="transparent"
            class="preview"
            loop
            autoplay
          >
          </lottie-player>

          <div class="d-flex align-center justify-center btn-wrappe">
            <a @click="downloadFile()" small icon>
              <v-icon>mdi-download-circle</v-icon></a
            >

            <a :href="src" target="blank" small icon>
              <v-icon>mdi-eye-circle</v-icon></a
            >
            <v-btn small icon v-if="!user.data.restricted">
              <v-icon  @click="openDialog()">mdi-pencil-circle</v-icon></v-btn
            >
            <v-btn small icon @click="exclude()" v-if="!user.data.restricted">
              <v-icon>mdi-delete-circle</v-icon></v-btn
            >
          </div>
        </v-card>
      </v-flex>
      <v-flex lg10 class="pa-6">
        <p class="semi-bold large mt-8">Detalhes</p>

        <SimpleTable :loading="loader" :headers="header" :items="Details" />

        <p class="semi-bold large mt-8">Histórico</p>

        <SimpleTable
          :loading="loader"
          :headers="header_historic"
          :items="Historic"
        />

        <p class="semi-bold large mt-8">Utilização</p>

        <SimpleTable
          :loading="loader"
          :headers="header_location"
          :items="Location"
          @ActionBtn="ActionButton"
        />
      </v-flex>
    </v-layout>
    <FileEditDialog
      :showDialog="showFileDialog"
      :snapshot="snapshot"
      v-model="showFileDialog"
      @update-file="updateFile"
      @closeDialog="closeDialog"
    ></FileEditDialog>
  </div>
</template>
<script>
import firebase from "firebase";
import FileEditDialog from "../../../components/Dialogs/FileEditDialog";
import { audioPlayer, videoPlayer, lottiePlayer } from "vue-md-player";
import { mapGetters } from 'vuex'

export default {
  props: {
    file: {
      type: [Array],
    },
  },
  data() {
    return {
      Details: [],
      snapshot: null,
      Historic: [],
      Location: [],
      showFileDialog: false,
      loader: false,

      src: "",
      header: [
        {
          text: "Descrição",
          value: "description",
          align: "center",
          sortable: false,
        },
        {
          text: "Data de criação",
          value: "created_details",
          align: "center",
          sortable: false,
        },
        // {
        //   text: "Última atualização",
        //   value: "updated_at",
        //   align: "center",
        //   sortable: false,
        // },
        { text: "Tipo", value: "type", align: "center", sortable: false },
        { text: "Tamanho", value: "size", align: "center", sortable: false },
        {
          text: "Extenção",
          value: "extension",
          align: "center",
          sortable: false,
        },
      ],
      header_historic: [
        {
          text: "Atualizado por",
          value: "user",
          align: "center",
          sortable: false,
        },
        {
          text: "Ação",
          value: "user_action",
          align: "center",
          sortable: false,
        },
        {
          text: "Ultima Atualização",
          value: "created_at",
          align: "center",
          sortable: false,
        },
      ],
      header_location: [
        {
          text: "Atualizado por",
          value: "user",
          width: "25%",
          align: "center",
        },

        { text: "Email", value: "userEmail", align: "center" },
        { text: "Descrição", value: "description", align: "center" },
        // { text: "Data da criação", value: "created_at", align: "center", },
        { text: "Domain", value: "domain", align: "center" },
        { text: "", value: "action" },
      ],
    };
  },

  computed: {
    routerParams() {
      return this.$route.params.id;
    },

    ...mapGetters({
    user: "user",
    }),
  },

  methods: {
    openDialog() {
      (this.snapshot = this.Details[0]), (this.showFileDialog = true);
    },
    closeDialog() {
      (this.snapshot = null), (this.showFileDialog = false);
    },
    downloadFile() {
      const storageRef = firebase.storage();
      const { url, description } = this.Details[0];
      storageRef
        .ref(url)
        .getDownloadURL()
        .then(function (url) {
          var xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = function () {
            var blob = xhr.response;

            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = description;
            link.click();
            URL.revokeObjectURL(link.href);
          };
          xhr.open("GET", url);
          xhr.send();
        })
        .catch(function (error) {
          console.log("err", error);
        });
    },
    goBack() {
      this.$router.go(-1);
    },

    async updateFile() {
      this.src = null;
      await this.getFileInformation();
      this.showFileDialog = false;
    },
    exclude() {
      if (this.Location.length !== 0) {
        alert("Arquivo não pode ser deletado pois está sendo utilizado");
      } else {
        if (confirm("Tem certeza que deseja deletar ?")) {
          var storageRef = firebase.storage().ref(this.Details[0].url);
          storageRef.delete();

          var asset = this.$database
            .collection("assets")
            .where("id", "==", this.Details[0].id);
          asset.get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              doc.ref.delete();
            });
          });
          this.goBack();
          this.$store.commit("SET_ALERT", "Item deletado!");
        }
      }
    },
    getImage(i) {
      const storageRef = firebase.storage().ref(i.url);
      return storageRef
        .getDownloadURL()
        .then((url) => {
          this.src = url;
        })
        .catch(() => {
          this.src =
            "https://www.cetegeducacao.com.br/wp-content/themes/cetegeducacao/assets/dist/img/no-image.png";
        });
    },

    getFileInformation() {
      let docRef = this.$database.collection("assets").doc(this.routerParams);
      docRef.get().then((doc) => {
        const data = [];
        data.push(doc.data());

        this.getImage(doc.data());

        this.Details = data;
      });
    },
    getHistory() {
      let docref = this.$database.collection(
        "assets/" + this.routerParams + "/history"
      );
      docref
        .orderBy("created_at", "desc")
        .get()
        .then((doc) => {
          if (doc.empty !== true) {
            let data = [];
            doc.forEach((doc) => {
              data.push(doc.data());
            });
            this.Historic = data;
          }
        });
    },
    getUsedIn() {
      let docref = this.$database.collection(
        "assets/" + this.routerParams + "/used_in"
      );
      docref.get().then((doc) => {
        if (doc.empty !== true) {
          let data = [];
          doc.forEach((doc) => {
            data.push(doc.data());
          });

          this.Location = data;
          console.log(this.Location);
        }
      });
    },

    ActionButton(e) {
      console.log(e);
      if (e.axis_id) {
        this.$router.push(`/domain/${e.domain}/qt/${e.question_id}`);
      } else if (e.bundle_id) {
        this.$router.push(
          `/domain/${e.domain}/atividades_digitais/${e.question_id}`
        );
      }
      // this.$router.push(`/domain/${e.domain}/qt/${e.question_id}`);
    },
  },
  watch: {
    routerParams() {
      this.getFileInformation();
      this.getHistory();
      this.getUsedIn();
    },
  },

  async created() {
    this.getFileInformation();
    this.getHistory();
    this.getUsedIn();
  },
  components: { audioPlayer, videoPlayer, lottiePlayer, FileEditDialog },
};
</script>
<style>
.image-cardy {
  max-height: 500px;
  max-width: 700px;

  object-fit: scale-down;
}
.imag {
  object-fit: scale-down;
  max-height: 500px !important;
  border-radius: 23px;
  width: 400px !important;

  /* border-radius: 0px !important; */
}
.normal {
  margin-top: -8px;
}
.wrapper {
  width: 100%;
}

.details-card {
  height: 180px;
  border-radius: 13px !important;
}
ul li {
  list-style-type: none;
}
.btn-wrappe {
  background: white;
  padding: 10px;
  -webkit-border-top-right-radius: 22px !important;
  -webkit-border-bottom-right-radius: 1px !important;
  -moz-border-radius-topright: 22px !important;
  -moz-border-radius-bottomright: 1px !important;
  border-top-right-radius: 22px !important;
  border-bottom-right-radius: 1px !important;
  position: absolute;
  top: -0px;
  right: -0px;

  height: 35px;
  width: 120px;
}
a {
  text-decoration: none;
}
</style>
