<template>
  <v-app>
    <div class="d-flex justify-end">
      <v-sheet outlined rounded class="sheet-border d-inline-flex pr-3">
        <v-row>
          <v-text-field
            v-model="searchTerm"
            rounded
            hide-details
            dense
            clearable
            class="mt-2 mb-2"
            style="max-width: 300px"
            placeholder="Buscar"
            disabled
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>

          <v-divider vertical></v-divider>

          <Button
            to="/md/create"
            tipo="searchIcon"
            label="Novo"
            icon="bi bi-plus-circle"
          />
        </v-row>
      </v-sheet>
    </div>

    <v-row class="mt-8 pa-lg-6 pa-md-3">
      <v-col
        v-for="model in models"
        :key="model.id"
        cols="12"
        lg="3"
        sm="6"
        xl="2"
        md="4"
      >
        <ModelCard :model="model.data()" @click="`/md/${model.id}`"></ModelCard>
      </v-col>
    </v-row>

    <infinite-loading
      v-if="models.length > 7"
      @infinite="infiniteHandler"
      spinner="waveDots"
    >
      <div slot="no-more">
        <div class="text-h3 mt-4">🙆‍♂️</div>
        <div>Você chegou ao fim.</div>
      </div>
    </infinite-loading>
  </v-app>
</template>

<script>
import ModelCard from "../../components/Cards/ModelCard";
export default {
  name: "Model",
  components: {
    ModelCard,
  },

  data: () => ({
    models: [],
    lastVisibleItem: null,
    searchTerm: "",
  }),

  watch: {
    searchTerm() {
      this.search();
    },
  },

  methods: {
    async search() {
      const data = {
        collection: "models",
        orderBy: "id",
        limit: 8,
      };

      await this.$getData(data).then((res) => {
        res.forEach((doc) => {
          this.models.push(doc);
        });
        this.lastVisibleItem = res[res.length - 1];
      });
    },

    infiniteHandler($state) {
      this.$database
        .collection("models")
        // .where("tags", "array-contains", this.search)
        .orderBy("id", "asc")
        .startAfter(this.lastVisibleItem)
        .limit(8)
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((doc) => {
            this.models.push(doc);
          });
          this.lastVisibleItem =
            querySnapshot.docs[querySnapshot.docs.length - 1];
          if (this.lastVisibleItem) {
            $state.loaded();
          } else {
            $state.complete();
          }
        });
    },
  },

  created() {
    this.search();
  },
};
</script>

<style scoped>
.sheet-border {
  border-radius: 25px !important;
}
</style>
