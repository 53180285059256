<template>
  <v-card class="info-card" outlined v-if="file">
    <v-card flat class="preview img-wrapper">
      <v-img
        class="path"
        v-if="(src && file.type === 'IMAGE') || file.type === 'AUDIO'"
        :class="file.type === 'IMAGE' ? 'image' : 'small-image'"
        contain
        :src="
          file.type === 'AUDIO'
            ? 'https://png.pngtree.com/png-vector/20190328/ourmid/pngtree-speaker-icon-design--essential-icon-vector-design-png-image_877696.jpg'
            : src
        "
        @click="changePath(file)"
      >
      </v-img>
      <audio-player v-if="src && file.type === 'AUDIO'">
        <source :src="src" />
      </audio-player>

      <video-player v-if="src && file.type === 'VIDEO'" class="preview">
        <source :src="src" />
      </video-player>

      <lottie-player
        @click="changePath(file)"
        v-if="src && file.type === 'LOTTIE'"
        :src="src"
        background="transparent"
        class="preview path"
        loop
        autoplay
      >
      </lottie-player>
    </v-card>

    <v-list-item two-line @click="changePath(file)">
      <v-list-item-content>
        <v-list-item-title class="larger text-capitalize">
          {{
            file.description
              .substring(0, 50)
              .replace(/_/g, " ")
              .replace(/-/g, " ")
          }}
        </v-list-item-title>
        <v-list-item-subtitle class="subtitle-file thin mt-2">
          <div v-if="this.$route.path == '/fl'">
            {{ file.updated_at | formatDatePay }}
          </div>
          <div v-else>{{ file.updated_at | formatDate }}</div>
          ∙
          {{ file.usage_counter ? file.usage_counter : 0 }}
          {{ file.usage_counter === 1 ? "Questão" : "Questões" }} ∙
          {{ file.size_text }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import firebase from "firebase";
import { audioPlayer, videoPlayer, lottiePlayer } from "vue-md-player";
import "vue-md-player/dist/vue-md-player.css";

export default {
  name: "FileCard",

  components: {
    audioPlayer,
    videoPlayer,
    lottiePlayer,
  },

  props: {
    snapshot: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    file: null,
    src: null,
    show: false,

    showFileDialog: false,
  }),

  computed: {
    typeIcon() {
      let icon = null;
      switch (this.file.type) {
        case "IMAGE":
          break;
        case "AUDIO":
          break;
        case "LOTTIE":
          break;
        case "VIDEO":
          break;
        default:
          break;
      }
      return icon;
    },
  },

  methods: {
    changePath(file) {
      this.$router.push({
        path: `/fl/details/${file.id}`,
        params: {
          file: file,
        },
      });
    },
    getImage() {
      if (!this.file.url) return;
      const storageRef = firebase.storage().ref(this.file.url);
      storageRef
        .getDownloadURL()
        .then((url) => {
          this.src = url;
        })
        .catch((err) => {
          console.log("error", err);
          this.src =
            "https://www.cetegeducacao.com.br/wp-content/themes/cetegeducacao/assets/dist/img/no-image.png";
        });
    },
  },
  watch: {
    snapshot() {
      this.src = null;
      this.file = this.snapshot;
      this.getImage();
    },
  },

  created() {
    this.file = this.snapshot;
    this.getImage();
  },
};
</script>

<style scoped>
.preview {
  height: 177px;

  border-top-right-radius: 13px;
  border-top-left-radius: 10px;
}
.card-text {
  height: 65px;
}
.card-text:hover {
  color: #143a57;
}

.image {
  width: 100%;
  position: absolute;
  top: 0;
  margin-top: 0px !important;
  height: 176px !important;

  object-fit: cover !important;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.small-image {
  width: 100%;
  height: 65%;
  object-fit: cover !important;
}
.info-card {
  border-radius: 10px;
}
.card-information {
  position: absolute;
  z-index: 10;
  width: 100%;
}
.text-info {
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomleft: 5px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 5px;
  height: 25px;
}
.path {
  cursor: pointer;
}
</style>
