<template>
  <v-container>
    <v-row class="mb-4 d-flex justify-center">
      <v-btn-toggle v-model="gridlistToogle" mandatory>
        <v-btn>
          <v-icon>mdi-grid</v-icon>
        </v-btn>
        <v-btn>
          <v-icon>mdi-view-list-outline</v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :disabled="!fileRecordsForUpload.length"
        @click="uploadFiles()"
        >Enviar {{ fileRecordsForUpload.length }} arquivos
      </v-btn>
    </v-row>
    <VueFileAgent
      ref="vueFileAgent"
      maxSize="25MB"
      helpText="Selecione arquivos ou arraste e solte aqui"
      :multiple="true"
      :editable="true"
      :deletable="true"
      :meta="true"
      :accept="'image/*,video/*,audio/*,.json'"
      :theme="gridlist[gridlistToogle]"
      :errorText="{
        type: 'Arquivo de extensão não aceita. Apenas images, audios, vídeos e lottie são aceitos.',
        size: 'Arquivos não devem ultrapassar 25MB',
      }"
      @select="filesSelected($event)"
      @beforedelete="onBeforeDelete($event)"
      @delete="fileDeleted($event)"
      v-model="fileRecords"
    >
    </VueFileAgent>
  </v-container>
</template>

<script>
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";
import Vue from "vue";
import firebase from "firebase";

Vue.use(VueFileAgentStyles);
let db = null;

export default {
  data() {
    return {
      gridlistToogle: 0,
      gridlist: ["grid", "list"],
      fileRecords: [],
      fileRecordsForUpload: [],
      types: [
        {
          mime: "image",
          type: "IMAGE",
        },
        {
          mime: "audio",
          type: "AUDIO",
        },
        {
          mime: "json",
          type: "LOTTIE",
        },
        {
          mime: "video",
          type: "VIDEO",
        },
      ],
    };
  },

  methods: {
    getType(mime) {
      for (let type of this.types) {
        if (mime.includes(type.mime)) return type.type;
      }
      return null;
    },
    uploadFiles() {
      for (let file of this.fileRecordsForUpload) {
        let desc = file.customName
          ? file.customName
          : file.file.name.split(".")[0];
        desc = desc.toLowerCase();

        let asset = {
          description: desc,
          type: this.getType(file.type),
          mime: file.type,
          size: file.size,
          size_text: file.sizeText,
          extension: file.ext,
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
        };

        db.collection("assets")
          .add(asset)
          .then(function (docRef) {
            var storageRef = firebase.storage().ref();
            var fileRef = storageRef.child(`assets/${docRef.id}`);
            var uploadTask = fileRef.put(file.file);
            uploadTask.on(
              "state_changed",
              function (snapshot) {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                file.progress(progress);
              },
              function (error) {
                // Handle unsuccessful uploads
                console.log(error);
              },
              function () {
                docRef.update({
                  id: docRef.id,
                  url: uploadTask.snapshot.ref.fullPath,
                });
              }
            );
            console.log(docRef);
          })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          });
      }

      this.fileRecordsForUpload = [];
      this.$store.commit("SET_ALERT", "Item enviado!");
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Deseja mesmo deletar?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  },

  created() {
    db = firebase.firestore();
  },
};
</script>

<style></style>
