<template>
  <v-container>
    <div class="d-flex justify-end mb-4">
      <v-btn
          @click="saveQuestionsPosition(syncDev, 'test')"
          color="primary"
          :disabled="syncDev"
          class="mr-2"
      >Salvar Ordenação [DEV]
      </v-btn>
      <v-btn
          @click="saveQuestionsPosition(syncProd, 'prod')"
          color="primary"
          :disabled="syncProd"
          class="mr-2"
      >Salvar Ordenação [PROD]
      </v-btn>
    </div>

    <Tree :value="nestedPlanets" :ondragend="onEnd">

      <v-card
          class="mx-auto"
          color="primary"
          slot-scope="{node}"
          dark>

        <v-card-actions>
          <v-list-item class="grow">
            <v-list-item-avatar>
              <v-img
                  class="elevation-6"
                  alt=""
                  :src="node.url"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ node.title }} - {{ node.id }}</v-list-item-title>
              <v-list-item-subtitle>Posição: {{ node.position }}・Level: {{ node.level }}・updated_at:
                {{ node.updated_at | formatDate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </Tree>
  </v-container>
</template>

<script>
// import draggable from "vuedraggable";
import firebase from "firebase";
import {Draggable, Tree} from 'he-tree-vue'
import 'he-tree-vue/dist/he-tree-vue.css'

export default {
  name: "SortPlanets",

  components: {
    // draggable,
    Tree: Tree.mixPlugins([Draggable])
  },

  data() {
    return {
      planets: [],
      nestedPlanets: [],
      loading: false,
      sortPlanets: false,

      syncDev: false,
      syncProd: false,
    };
  },

  methods: {

    onEnd(_, store) {
      let isAllowed = store.targetPath.filter(value => value === 0).length < 2
      console.log("MOVIMENTO PERMITIDO?", isAllowed)
      if (!isAllowed)
        console.log("PRECISO MOVER DE: ", store.targetPath, "PARA: ", store.startPath)

      //TODO: implementar bloqueio de movimentos com profundidade > 1
    },

    setupNested() {
      const familyTree = []
      for (let position in this.planets) {
        let planet = this.planets[position]
        let isChildren = this.planetChildren(familyTree, planet["id"])
        if (planet["next_bundle_id"]) {
          // verifico se ele não é filho de algum planeta
          if (isChildren != null) {
            // caso sim: Adiciono o next_bundle_id como um dos filhos do pai do mesmo
            isChildren.children.push(planet["next_bundle_id"])

            let root = this.nestedPlanets.filter(item => isChildren.parent === item.id)[0]
            root.children.push(this.planets[++position])
          } else {
            // caso não: adiciono ele como pai e o planeta seguinte como filho
            let item = {
              parent: planet["id"],
              children: [planet["next_bundle_id"]]
            }
            familyTree.push(item)

            planet.children = [this.planets[++position]]
            this.nestedPlanets.push(planet)
          }
        } else if (isChildren == null) {
          this.nestedPlanets.push(planet)
        }
      }
    },

    planetChildren(familyTree, planetId) {
      for (let index in familyTree) {
        if (familyTree[index].children.includes(planetId)) {
          return familyTree[index]
        }
      }
      return null
    },

    saveQuestionsPosition(bool, env) {
      console.log(bool, env)

      let index = 0
      for (let position in this.nestedPlanets) {
        let planet = this.nestedPlanets[position]
        planet.position = index
        planet.next_bundle_id = null

        if (planet.children != null) {
          for (let childrenIndex in planet.children) {
            index++

            let childPlanet = planet.children[childrenIndex]
            childPlanet.position = index
            childPlanet.next_bundle_id = null

            if (childrenIndex == 0) {
              planet.next_bundle_id = childPlanet.id
            } else {
              planet.children[(childrenIndex - 1)].next_bundle_id = childPlanet.id
            }
          }
        }

        index++
      }

      for (let position in this.nestedPlanets) {
        let planet = this.nestedPlanets[position]
        let bundle = this.planets.filter(item => item.id === planet.id)[0]
        bundle.position = planet.position
        bundle.next_bundle_id = planet.next_bundle_id

        if (planet.children != null) {
          for (let childrenIndex in planet.children) {
            let childPlanet = planet.children[childrenIndex]
            let bundle = this.planets.filter(item => item.id === childPlanet.id)[0]
            bundle.position = childPlanet.position
            bundle.next_bundle_id = childPlanet.next_bundle_id
          }
        }
      }

      let db = firebase.firestore();

      for (let position in this.planets) {
        let docRef = db.collection("bundles");
        const doc = docRef.doc(this.planets[position].id);

        doc.update({
          position: parseInt(this.planets[position].position),
          next_bundle_id: this.planets[position].next_bundle_id,
        });
      }

      this.requestSync(bool, env);
    },

    fetchUrlPaths(planet) {
      if (!planet.avatar) return;
      const storageRef = firebase.storage().ref(planet.avatar);
      storageRef.getDownloadURL().then((url) => {
        planet.url = url;
      });
    },

    loadBundles() {
      const db = firebase.firestore();

      const axisId = this.$route.params.axisId;

      db.collection("bundles")
          .where("axis_id", "==", axisId)
          .orderBy("position")
          .get()
          .then((querySnapshot) => {
            this.planets = [];
            querySnapshot.docs.forEach((doc) => {
              const planet = doc.data()
              this.planets.push(planet);
              this.fetchUrlPaths(planet)
            });
            this.setupNested()
          });
    },

    requestSync(bool, env) {
      if (env === "prod") {
        this.syncProd = true;
      } else {
        this.syncDev = true;
      }

      const axisId = this.$route.params.axisId;
      const domain = this.$route.params.domain;

      let db = firebase.firestore();
      let docRef = db.collection("request_sorting_da");
      const doc = docRef.doc();

      const request = {
        id: doc.id,
        env: env,
        domain_code: domain,
        axis_id: axisId,
        req_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        status: "PENDING",
        userId: firebase.auth().currentUser.uid,
      };

      doc
          .set(request)
          .then(() => {
            bool = true;

            this.$router.push({name: "planet-list"});
            this.sortPlanets = false;
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
    },
  },

  created() {
    this.loadBundles();
  },
};
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}

.my-tree-view .tree-node {
}

</style>
