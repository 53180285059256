var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closePanel),expression:"closePanel"}]},[_c('ais-instant-search',{attrs:{"search-client":_vm.searchClient,"index-name":_vm.indexName}},[_c('div',{on:{"click":_vm.expandPanel}},[_c('ais-search-box',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
var refine = ref.refine;
return [_c('input',{staticClass:"field",class:_vm.expanded ? 'expand' : '',attrs:{"type":"search","placeholder":"Buscar"},domProps:{"value":currentRefinement},on:{"input":function($event){return refine($event.currentTarget.value)}}})]}}])})],1),(_vm.expanded)?_c('v-card',{staticClass:"search-card"},[_c('ais-hits',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('v-list',{attrs:{"dense":""}},[_c('v-tabs',_vm._l((_vm.Tabs),function(tabs,i){return _c('v-tab',{key:i,staticClass:"normal semibold",attrs:{"disabled":tabs.disabled},on:{"click":function($event){return _vm.handleFilterChange(tabs.value)}}},[_vm._v(_vm._s(tabs.text)+" ")])}),1),_c('v-divider'),_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"grey lighten-5"},[_c('span',{staticClass:"medium-text semibold"},[_vm._v("Filters")]),_c('v-spacer')],1),_c('v-expansion-panel-content',[(_vm.indexName === 'assets')?_c('div',{staticClass:"search-panel__filters"},[_c('span',{staticClass:"medium-text light"},[_vm._v("Tipo")]),_c('ais-refinement-list',{attrs:{"attribute":"type"}})],1):_vm._e(),(_vm.indexName === 'questions')?_c('div',{staticClass:"search-panel__filters d-flex"},[_c('div',[_c('h5',{staticClass:"medium-text light"},[_vm._v("Tipo")]),_c('ais-refinement-list',{attrs:{"attribute":"model_id"}})],1),_c('div',{staticClass:"ml-8"},[_c('h5',{staticClass:"medium-text light"},[_vm._v("Domain")]),_c('ais-refinement-list',{attrs:{"attribute":"domain"}})],1)]):_vm._e()]),_c('v-divider',{staticClass:"grey lighten-2"})],1)],1),_c('ais-stats',{staticClass:"ma-3 normal thin",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var nbPages = ref.nbPages;
var nbHits = ref.nbHits;
return [_c('span',{staticClass:"normal gray-300"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(nbHits))+" Resultados encontrado. ")])]}}],null,true)}),_c('v-virtual-scroll',{attrs:{"height":"300","item-height":"35","items":items},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"info-card",on:{"click":function($event){return _vm.onItemSelected(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-folder-star-multiple ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"medium-text bold grey-300",domProps:{"textContent":_vm._s(
                        item.description ? item.description : item.title
                      )}})],1),_c('v-list-item-content',{staticClass:"ml-3 text-center"},[_c('v-list-item-title',{staticClass:"normal text-capitalize thin grey-200"},[_vm._v(" "+_vm._s(item.type ? item.type : item.status))])],1)],1)]}}],null,true)})],1)]}}],null,false,4246950473)}),_c('v-divider'),_c('ais-configure',{attrs:{"attributesToSnippet":['description:50'],"snippetEllipsisText":"…","hitsPerPage":20}}),_c('ais-pagination',{attrs:{"total-pages":6}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }