var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ais-instant-search',{attrs:{"search-client":_vm.searchClient,"index-name":"assets"}},[_c('v-card',{staticClass:"pa-4 mb-2",attrs:{"outlined":""}},[_vm._t("default"),_c('v-row',[_c('TextField',{attrs:{"label":"Text","model":_vm.option.description,"cols":"6"},on:{"input":function($event){_vm.option.description = $event}}}),_c('TextField',{attrs:{"label":"Ordem","hint":"Utilizado em questões de ordenação","type":"number","model":_vm.option.position,"rules":"true","cols":"3"},on:{"input":function($event){_vm.option.position = $event}}}),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"label":"Resposta Correta"},model:{value:(_vm.option.isCorrect),callback:function ($$v) {_vm.$set(_vm.option, "isCorrect", $$v)},expression:"option.isCorrect"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ais-autocomplete',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutsideImage),expression:"onClickOutsideImage"}],scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
var refine = ref.refine;
var indices = ref.indices;
return [_c('v-card',{attrs:{"flat":""}},[_c('input',{staticClass:"fields",attrs:{"type":"search","focus":("this.value=" + currentRefinement),"placeholder":"Buscar por imagens"},domProps:{"value":_vm.imageAuto === true ? currentRefinement : _vm.imageDescription},on:{"click":function($event){(_vm.Type = 'IMAGE'), (_vm.audioAuto = false)},"input":function($event){refine($event.currentTarget.value), (_vm.imageAuto = true)}}}),(currentRefinement && _vm.imageAuto == true)?_c('v-card',{staticClass:"card-details"},_vm._l((indices),function(index){return _c('ul',{key:index.indexId},_vm._l((index.hits),function(hit){return _c('li',{key:hit.objectID,staticClass:"mb-2 list-picker pa-2 d-flex"},[_c('ais-highlight',{attrs:{"attribute":"description","hit":hit}}),_c('v-spacer'),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleImageSelected(hit)}}},[_vm._v("mdi-check-circle-outline")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.handleOpenImage(hit)}}},[_vm._v("mdi-eye")])],1)}),0)}),0):_vm._e()],1),_c('div',{staticClass:"d-flex mt-4 align-center"},[_c('span',{staticClass:"normal mt-2"},[_vm._v("IMAGEM:")]),_c('span',{staticClass:"normal bold mt-1 ml-4"},[_vm._v(" "+_vm._s(_vm.imageDescription ? _vm.imageDescription : "Não selecionada"))]),_c('v-spacer'),_c('v-icon',{staticClass:"mr-1",on:{"click":function($event){return _vm.removeImage()}}},[_vm._v("mdi-close")]),_c('v-icon',{on:{"click":function($event){return _vm.handleOpenImage()}}},[_vm._v("mdi-eye")])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ais-autocomplete',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutsideAudio),expression:"onClickOutsideAudio"}],scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
var refine = ref.refine;
var indices = ref.indices;
return [_c('v-card',{attrs:{"flat":""}},[_c('input',{staticClass:"fields",attrs:{"focus":("this.value=" + currentRefinement),"type":"search","placeholder":"Buscar por audios"},domProps:{"value":_vm.audioAuto === true ? currentRefinement : _vm.soundDescription},on:{"click":function($event){(_vm.Type = 'AUDIO'), (_vm.imageAuto = false)},"input":function($event){refine($event.currentTarget.value),
                      (_vm.audioAuto = true),
                      (_vm.imageAuto = false)}}}),(currentRefinement && _vm.audioAuto == true)?_c('v-card',{staticClass:"card-details"},_vm._l((indices),function(index){return _c('ul',{key:index.indexId},_vm._l((index.hits),function(hit){return _c('li',{key:hit.objectID,staticClass:"mb-2 list-picker pa-2 d-flex"},[_c('ais-highlight',{attrs:{"attribute":"description","hit":hit}}),_c('v-spacer'),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleImageSelected(hit)}}},[_vm._v("mdi-check-circle-outline")]),_c('v-icon',{attrs:{"color":"blue","small":""},on:{"click":function($event){return _vm.playAudio(hit)}}},[_vm._v("mdi-play-circle-outline")])],1)}),0)}),0):_vm._e()],1),_c('div',{staticClass:"d-flex mt-4 align-center"},[_c('span',{staticClass:"normal mt-2"},[_vm._v("AUDIO:")]),_c('span',{staticClass:"normal bold mt-1 ml-4"},[_vm._v(" "+_vm._s(_vm.soundDescription ? _vm.soundDescription : "Não selecionado"))]),_c('v-spacer'),_c('v-icon',{staticClass:"mr-1",on:{"click":function($event){return _vm.removeAudio()}}},[_vm._v("mdi-close")]),_c('v-icon',{attrs:{"color":"blue"},on:{"click":function($event){return _vm.playAudio()}}},[_vm._v("mdi-play-circle-outline")])],1)]}}])})],1)],1)],2),_c('ais-configure',{attrs:{"attributesToSnippet":['description:50'],"snippetEllipsisText":"…","hitsPerPage":5,"filters":("type=" + _vm.Type)}})],1),_c('ImageDialog',{attrs:{"dialog":_vm.dialog,"dialogData":_vm.dialogData},on:{"handleClose":function($event){_vm.dialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }