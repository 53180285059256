import Vue from "vue";
import Vuex from "vuex";
import axis from "@/store/modules/axis";
import Auth from './modules/Auth'

Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        axis,
        Auth
    },

});