<template>
  <div class="text-center ma-2" v-if="bar">
    <v-snackbar color="green lighten-1" v-model="snackbar" right top>
      <v-icon class="mr-3">mdi-check-circle</v-icon>
      <span class="medium-text"> {{ bar.value }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  props: ["bar", "type", "text"],
  data() {
    return {
      snackbar: false,
    };
  },
  watch: {
    bar() {
      if (this.bar) {
        this.snackbar = true;
      }
    },
  },
};
</script>
