<template>
  <v-layout align-content-center justify-center>
    <lottie-player
      src="https://assets4.lottiefiles.com/packages/lf20_RkWAMt.json"
      background="transparent"
      speed="0.7"
      style="width: 500px; height: 500px"
      loop
      autoplay
    ></lottie-player>
  </v-layout>
</template>

<script>
export default {
  name: "InProgress",
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped></style>
