<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <ais-instant-search :search-client="searchClient" index-name="assets">
      <v-card outlined class="pa-4 mb-2">
        <slot></slot>

        <v-row>
          <TextField
            label="Text"
            :model="option.description"
            @input="option.description = $event"
            cols="6"
          />
          <TextField
            label="Ordem"
            hint="Utilizado em questões de ordenação"
            type="number"
            :model="option.position"
            @input="option.position = $event"
            rules="true"
            cols="3"
          />
          <v-col cols="3">
            <v-checkbox
              v-model="option.isCorrect"
              label="Resposta Correta"
            ></v-checkbox>
          </v-col>
          <v-col cols="6">
            <ais-autocomplete v-click-outside="onClickOutsideImage">
              <template v-slot="{ currentRefinement, refine, indices }">
                <v-card flat>
                  <input
                    type="search"
                    @click="(Type = 'IMAGE'), (audioAuto = false)"
                    class="fields"
                    :focus="`this.value=${currentRefinement}`"
                    :value="
                      imageAuto === true ? currentRefinement : imageDescription
                    "
                    placeholder="Buscar por imagens"
                    @input="
                      refine($event.currentTarget.value), (imageAuto = true)
                    "
                  />
                  <v-card
                    class="card-details"
                    v-if="currentRefinement && imageAuto == true"
                  >
                    <ul v-for="index in indices" :key="index.indexId">
                      <li
                        class="mb-2 list-picker pa-2 d-flex"
                        v-for="hit in index.hits"
                        :key="hit.objectID"
                      >
                        <ais-highlight attribute="description" :hit="hit" />
                        <v-spacer></v-spacer>
                        <v-icon
                          class="mr-2"
                          @click="handleImageSelected(hit)"
                          small
                          >mdi-check-circle-outline</v-icon
                        >
                        <v-icon small @click="handleOpenImage(hit)"
                          >mdi-eye</v-icon
                        >
                      </li>
                    </ul>
                  </v-card>
                </v-card>
                <div class="d-flex mt-4 align-center">
                  <span class="normal mt-2">IMAGEM:</span>
                  <span class="normal bold mt-1 ml-4">
                    {{
                      imageDescription ? imageDescription : "Não selecionada"
                    }}</span
                  >
                  <v-spacer></v-spacer>
                  <v-icon @click="removeImage()" class="mr-1">mdi-close</v-icon>
                  <v-icon @click="handleOpenImage()">mdi-eye</v-icon>
                </div>
              </template>
            </ais-autocomplete>
          </v-col>
          <v-col cols="6">
            <ais-autocomplete v-click-outside="onClickOutsideAudio">
              <template v-slot="{ currentRefinement, refine, indices }">
                <v-card flat>
                  <input
                    :focus="`this.value=${currentRefinement}`"
                    type="search"
                    class="fields"
                    :value="
                      audioAuto === true ? currentRefinement : soundDescription
                    "
                    @click="(Type = 'AUDIO'), (imageAuto = false)"
                    placeholder="Buscar por audios"
                    @input="
                      refine($event.currentTarget.value),
                        (audioAuto = true),
                        (imageAuto = false)
                    "
                  />
                  <v-card
                    class="card-details"
                    v-if="currentRefinement && audioAuto == true"
                  >
                    <ul v-for="index in indices" :key="index.indexId">
                      <li
                        class="mb-2 list-picker pa-2 d-flex"
                        v-for="hit in index.hits"
                        :key="hit.objectID"
                      >
                        <ais-highlight attribute="description" :hit="hit" />

                        <v-spacer></v-spacer>
                        <v-icon
                          class="mr-2"
                          @click="handleImageSelected(hit)"
                          small
                          >mdi-check-circle-outline</v-icon
                        >
                        <v-icon color="blue" small @click="playAudio(hit)"
                          >mdi-play-circle-outline</v-icon
                        >
                      </li>
                    </ul>
                  </v-card>
                </v-card>
                <div class="d-flex mt-4 align-center">
                  <span class="normal mt-2">AUDIO:</span>
                  <span class="normal bold mt-1 ml-4">
                    {{
                      soundDescription ? soundDescription : "Não selecionado"
                    }}</span
                  >
                  <v-spacer></v-spacer>
                  <v-icon @click="removeAudio()" class="mr-1">mdi-close</v-icon>
                  <v-icon color="blue" @click="playAudio()"
                    >mdi-play-circle-outline</v-icon
                  >
                </div>
              </template>
            </ais-autocomplete>
          </v-col>
        </v-row>
      </v-card>
      <ais-configure
        :attributesToSnippet="['description:50']"
        snippetEllipsisText="…"
        :hits-per-page.camel="5"
        :filters="`type=${Type}`"
      />
    </ais-instant-search>
    <ImageDialog
      @handleClose="dialog = false"
      :dialog="dialog"
      :dialogData="dialogData"
    />
  </div>
</template>

<script>
import firebase from "firebase";
import ImageDialog from "../../Dialogs/ImageDialog.vue";
let db = null;
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
export default {
  name: "OptionForm",
  components: {
    ImageDialog,
  },

  props: {
    option: {
      type: Object,
      default: () => ({
        description: null,
        image_id: null,
        sound_id: null,
        isCorrect: false,
        position: null,
        src: null,
      }),
    },
  },

  data() {
    return {
      Type: "IMAGE",
      file: null,
      dialogData: null,
      dialog: false,
      imageAuto: false,
      audioAuto: false,
      imageDescription: "",
      soundDescription: "",
      searchClient: instantMeiliSearch(
        sessionStorage.getItem("url"),
        sessionStorage.getItem("token")
      ),
      loadingImage: false,
      searchImage: null,
      imageFiles: [],
      imageWatcherDisabled: false,

      loadingSound: false,
      searchSound: null,
      soundFiles: [],
      soundWatcherDisabled: false,

      image_src: null,
      audio_src: null,
    };
  },
  methods: {
    onClickOutsideImage() {
      if (this.imageAuto === true) {
        this.imageAuto = false;
      }
    },
    onClickOutsideAudio() {
      if (this.audioAuto === true) {
        this.audioAuto = false;
      }
    },

    handleImageSelected(e) {
      if (e.type === "IMAGE") {
        this.option.image_id = e.id;
        this.option.url = e.url;
        this.file = e;
        this.imageDescription = e.description;
        this.imageAuto = false;
      } else if (e.type === "AUDIO") {
        this.option.sound_id = e.id;
        this.soundDescription = e.description;
        this.audioAuto = false;
      }
    },
    playAudio(e) {
      if (e) {
        const storageRef = firebase.storage().ref(`assets/${e.id}`);
        storageRef.getDownloadURL().then((url) => {
          var audio = new Audio(url);
          audio.play();
        });
      } else if (this.option.sound_id && !e) {
        const storageRef = firebase
          .storage()
          .ref(`assets/${this.option.sound_id}`);
        storageRef.getDownloadURL().then((url) => {
          var audio = new Audio(url);
          audio.play();
        });
      }
    },
    async handleOpenImage(e) {
      if (e) {
        const storageRef = firebase
          .storage()
          .ref(e.url ? e.url : this.option.url);
        await storageRef.getDownloadURL().then((url) => {
          e.src = url;
        });
        this.dialogData = e;
        this.dialog = true;
      } else if (this.image_src && !e) {
        let data = {
          src: this.image_src,
          type: this.file.type,
        };

        this.dialogData = data;
        this.dialog = true;
      }
    },
    removeImage() {
      this.option.image_id = null;
      this.option.url = null;
      this.imageDescription = null;
      this.image_src = null;
    },
    removeAudio() {
      this.option.sound_id = null;
      this.option.url = null;
      this.soundDescription = null;
    },
  },
  watch: {
    "option.image_id": function () {
      if (!this.option.image_id) return;

      //TODO: melhorar isso, pegar o path
      const storageRef = firebase
        .storage()
        .ref(`assets/${this.option.image_id}`);

      storageRef.getDownloadURL().then((url) => {
        this.image_src = url;
      });
    },

    searchImage(val) {
      if (!val || this.imageWatcherDisabled) return;

      this.loadingImage = true;

      let query = db
        .collection("assets")
        .where("searchTags", "array-contains-any", val.split(" "))
        .where("type", "==", "IMAGE")
        .orderBy("updated_at", "desc")
        .limit(15);

      query.get().then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          let image = doc.data();
          const storageRef = firebase.storage().ref(image.url);
          storageRef.getDownloadURL().then((url) => {
            image.src = url;
            this.imageFiles.push(image);
          });
        });

        this.loadingImage = false;
      });
    },

    "option.sound_id": function () {
      if (!this.option.sound_id) return;

      //TODO: melhorar isso, pegar o path
      const storageRef = firebase
        .storage()
        .ref(`assets/${this.option.sound_id}`);
      storageRef.getDownloadURL().then((url) => {
        this.audio_src = url;
      });
    },

    searchSound(val) {
      if (!val || this.soundWatcherDisabled) return;

      this.loadingSound = true;

      let query = db
        .collection("assets")
        .where("searchTags", "array-contains-any", val.split(" "))
        .where("type", "==", "AUDIO")
        .orderBy("updated_at", "desc")
        .limit(15);

      query.get().then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          let sound = doc.data();
          const storageRef = firebase.storage().ref(sound.url);
          storageRef.getDownloadURL().then((url) => {
            sound.src = url;
            this.soundFiles.push(sound);
          });
        });

        this.loadingSound = false;
      });
    },
  },

  mounted() {
    db = firebase.firestore();

    if (this.option.image_id) {
      this.loadingImage = true;
      this.imageWatcherDisabled = true;
      let query = db.collection("assets").doc(this.option.image_id);

      query.get().then((doc) => {
        this.imageFiles = [];

        if (doc.exists) {
          let file = doc.data();
          this.file = file;

          this.imageDescription = file.description;

          const storageRef = firebase.storage().ref(file.url);
          storageRef.getDownloadURL().then((url) => {
            file.src = url;
            this.image_src = url;
            this.imageFiles.push(file);

            this.loadingImage = false;
            this.imageWatcherDisabled = false;
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
    }

    if (this.option.sound_id) {
      this.loadingSound = true;
      this.soundWatcherDisabled = true;
      let query = db.collection("assets").doc(this.option.sound_id);

      query.get().then((doc) => {
        this.soundFiles = [];

        if (doc.exists) {
          let file = doc.data();

          this.soundDescription = file.description;
          const storageRef = firebase.storage().ref(file.url);
          storageRef.getDownloadURL().then((url) => {
            file.src = url;
            this.audio_src = url;
            this.soundFiles.push(file);

            this.loadingSound = false;
            this.soundWatcherDisabled = false;
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
    }
  },
};
</script>

<style scoped>
.list-picker {
  margin-left: -20px;
}
.fields {
  width: 100% !important;
  border-radius: 5px !important;
  height: 40px !important;
}
.card-details {
  position: absolute;
  z-index: 100;
  width: 100%;
}
input:focus {
  outline: none;
  border: 1px solid #0f8fef;
}
</style>
