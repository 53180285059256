<template>
  <v-app-bar app flat outlined clipped-left class="borda">
    <v-layout class="d-flex justify-around">
      <v-flex class="d-flex justify-start d-sm-none d-md-flex" sm2>
        <img src="@/assets/logo.svg" class="logo ml-10" alt="EduLab Logo" />
      </v-flex>
      <v-flex>
        <div class="d-flex justify-sm-start justify-md-center mt-3">
          <div v-for="item in menu.filter(m => !m.disabled)" :key="item.tooltip">
            <router-link :to="item.url" class="mr-3 semibold m-reponsive">
              {{ item.tooltip }}
            </router-link>
          </div>
        </div>
      </v-flex>

      <v-flex sm2 class="d-flex justify-end">
        <GlobalField />
        <v-avatar @click="handleAvatarClick">
          <v-img
            style="z-index: 1; cursor: pointer"
            :src="user.data.photoURL"
            :alt="user.data.displayName"
          />
        </v-avatar>
        <v-card
          max-width="344"
          class="user-card"
          v-if="userInfo"
          v-click-outside="handleAvatarClick"
        >
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-img
                  style="z-index: 1"
                  :src="user.data.photoURL"
                  :alt="user.data.displayName"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{
                  user.data.displayName
                }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.data.email
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item-group color="primary">
              <v-list-item @click="logout">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Sair</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-flex>
    </v-layout>
  </v-app-bar>
</template>
<script>

import { mapGetters } from "vuex";
import GlobalField from "../GlobalSearchField.vue";
import firebase from "firebase";
import store from "@/store/store";

export default {
  components: {
    GlobalField,
  },


  data: (context) => {
    return ({
    drawer: true,
    userInfo: false,
    menu: [
      {
        title: "mdi-dashboard",
        tooltip: "Dashboard",
        url: "/",
        disabled: context.$store.getters.user.data.restricted,
      },
      {
        title: "mdi-alpha-p-circle",
        tooltip: "Português",
        url: "/domain/PORTUGUESE/atividades_digitais",
        disabled: context.$store.getters.user.data.restricted
      },
      {
        title: "mdi-alpha-m-circle",
        tooltip: "Matemática",
        url: "/domain/MATH/qt",
        disabled: context.$store.getters.user.data.restricted
      },
      {
        title: "mdi-code-braces-box",
        tooltip: "Modelos",
        url: "/md",
        disabled: context.$store.getters.user.data.restricted,
      },
      {
        title: "mdi-file-multiple",
        tooltip: "Arquivos",
        url: "/fl",
        disabled: false
      },
      // {
      //   title: "mdi-movie",
      //   tooltip: "Vídeos",
      //   url: "/vd",
      //   disabled: true,
      // },
      // {
      //   title: "mdi-book",
      //   tooltip: "Materiais Adicionais",
      //   url: "/ma",
      //   disabled: true,
      // },
    ],
  })},

  methods: {
    handleAvatarClick() {
      this.userInfo = !this.userInfo;
    },

    logout() {
      store.dispatch("fetchUser", null);
      this.$store.commit("CLEAN_USER_DATA");
      firebase.auth().signOut();
    },

  },

  computed: {
    ...mapGetters({
      user: "user",
    }),
  },

};
</script>

<style scoped>
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-enter-active {
  animation: fade-in 0.3s ease;
}

.fade-leave-active {
  animation: fade-out 0.3s ease;
}

.logo {
  height: 42px;
  width: 42px;
}

.borda {
  z-index: 100 !important;
  border: 0 solid lightgray !important;
  border-bottom-width: 1px !important;
  background-color: white !important;
}
a {
  text-decoration: none;
  padding: 10px;
  border-radius: 10px;
  color: rgb(58, 57, 57) !important;
}
a:hover {
  background: #f3f5f7;
  color: #0077c8 !important;
}
a.router-link-exact-active {
  font-weight: 600 !important;
  background: #e5f0f8;
  color: #0077c8 !important;
}
.user-card {
  position: absolute;
  right: 0px;
  top: 64px;
}
</style>
