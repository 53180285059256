var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"transparent"},[_c('ais-instant-search',{attrs:{"search-client":_vm.searchClient,"index-name":"questions"}},[_c('div',{staticClass:"text-center"},[_c('ais-autocomplete',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
var refine = ref.refine;
return [_c('input',{staticClass:"fields",attrs:{"type":"search","placeholder":"Buscar arquivos"},domProps:{"value":currentRefinement},on:{"input":function($event){return refine($event.currentTarget.value)}}})]}}])}),_c('Button',{staticClass:"mt-1",attrs:{"large":"","to":"qt/create","icon":"bi bi-plus-circle","tipo":"primary newQuestion","label":"Novo"}}),_c('ais-refinement-list',{attrs:{"attribute":"axis_id","searchable":"","show-more":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var refine = ref.refine;
var createURL = ref.createURL;
return [_c('v-layout',{staticClass:"list-wrapper mt-10",attrs:{"wrap":""}},_vm._l((items),function(item){return _c('v-flex',{key:item.value,staticClass:"list",attrs:{"xs2":""},on:{"click":function($event){$event.preventDefault();return refine(item.value)}}},[_c('a',{staticClass:"normal bold",style:({ fontWeight: item.isRefined ? 'bold' : '' }),attrs:{"href":createURL(item)}},[_vm._v(" "+_vm._s(_vm.getEixoName(item))+" "),_c('span',{staticClass:"thin normal"},[_vm._v(" ("+_vm._s(item.count.toLocaleString())+") ")])])])}),1)]}}])}),_c('ais-stats',{staticClass:"ma-3 mt-5 normal thin",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var nbHits = ref.nbHits;
return [_c('span',{staticClass:"normal gray-300"},[_vm._v(" "+_vm._s(nbHits)+" Resultados encontrados. ")])]}}])})],1),_c('ais-hits',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('SimpleTable',{attrs:{"headers":_vm.headers,"items":items,"axis":_vm.axis,"sortable":""},on:{"ActionBtn":_vm.open}})]}}])}),_c('ais-configure',{attrs:{"filters":("domain=" + (this.$route.params.domain)),"attributesToSnippet":['description:50'],"snippetEllipsisText":"…","hitsPerPage":20}}),_c('ais-pagination',{attrs:{"total-pages":10}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }