import router from "../../router";

const state = {
  validatingUser: true,
  user: {
    loggedIn: false,
    data: null,
  },
  alert: null,
};

const getters = {
  user(state) {
    return state.user;
  },
  alert: (state) => state.alert,
  validatingUser(state) {
    return state.validatingUser;
  },
};

const mutations = {
  SET_LOGGED_IN(state, value) {
    state.user.loggedIn = value;
    state.validatingUser = false;
  },

  CLEAN_USER_DATA(state) {
    state.user.data = null;
    sessionStorage.clear();
    if (router.history.current.fullPath !== "/") {
      router.push("/");
    }
  },

  SET_USER(state, data) {
    state.user.data = data;
  },

  SET_ALERT(state, data) {
    (state.alert = {
      status: true,
      value: data,
    }),
      setTimeout(function () {
        state.alert = null;
      }, 2500);
  },
};

const actions = {
  fetchUser({ commit }, user) {
    commit("SET_LOGGED_IN", user !== null);
    if (user) {
      commit("SET_USER", {
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        restricted: user.restricted,
      });
    } else {
      commit("SET_USER", null);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
