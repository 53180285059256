<template>
  <v-dialog v-model="show" max-width="500px" persistent>
    <v-card :loading="loading" v-if="data">
      <v-card-title>
        <span class="headline">{{ data.type }}</span>
      </v-card-title>

      <v-card-text>
        <VueFileAgent
          v-model="file"
          ref="vueFileAgent"
          maxSize="25MB"
          helpText="Selecione arquivos ou arraste e solte aqui"
          :multiple="false"
          :deletable="true"
          :meta="false"
          :accept="'image/*,video/*,audio/*,.json'"
          :compact="true"
          :errorText="{
            type: 'Arquivo de extensão não aceita. Apenas imagem é aceita.',
            size: 'Arquivos não devem ultrapassar 25MB',
          }"
          style="background-color: gray"
          class="mb-4"
          @beforedelete="(file = null), (asset = null)"
          @select="filesSelected($event)"
        >
        </VueFileAgent>

        <TextField
          label="Descrição"
          :model="editDescription"
          @input="editDescription = $event"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <Button label="Cancelar" tipo="error" @ClickEvent="closeDialog()" />
        <Button
          label="Salvar"
          tipo="primary"
          @ClickEvent="save"
          :loading="saving"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "firebase";
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";
import Vue from "vue";

Vue.use(VueFileAgentStyles);

export default {
  name: "FileEditDialog",

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    snapshot: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    loading: true,
    saving: false,
    editDescription: "",
    fileRecordsForUpload: [],
    data: null,
    file: null,
    asset: null,
    types: [
      {
        mime: "image",
        type: "IMAGE",
      },
      {
        mime: "audio",
        type: "AUDIO",
      },
      {
        mime: "json",
        type: "LOTTIE",
      },
      {
        mime: "video",
        type: "VIDEO",
      },
    ],
  }),

  computed: {
    show: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    getType(mime) {
      for (let type of this.types) {
        if (mime.includes(type.mime)) return type.type;
      }
      return null;
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);
      for (let file of this.fileRecordsForUpload) {
        let desc = file.customName
          ? file.customName
          : file.file.name.split(".")[0];
        desc = desc.toLowerCase();

        this.asset = {
          type: this.getType(file.type),
          mime: file.type,
          size: file.size,
          size_text: file.sizeText,
          extension: file.ext,
        };
      }
    },

    uploadImage(docRef) {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`assets/${docRef.id}`);
      const uploadTask = fileRef.put(this.file.file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.file.progress(progress);
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          docRef.update({
            url: uploadTask.snapshot.ref.fullPath,
          });

          this.data.url = uploadTask.snapshot.ref.fullPath;

          this.$emit("update-file", this.data);
        }
      );
    },

    save() {
      if (this.asset) {
        this.data.type = this.asset.type;
        this.data.mime = this.asset.mime;
        this.data.size = this.asset.size;
        this.data.size_text = this.asset.size_text;
        this.data.extension = this.asset.extension;
        this.data.updated_at = firebase.firestore.FieldValue.serverTimestamp();
      }
      let db = firebase.firestore();
      let docRef = db.collection("assets").doc(this.snapshot.id);
      this.data.description = this.editDescription.toLowerCase();

      docRef
        .set(this.data)
        .then(() => {
          if (this.file.file) this.uploadImage(docRef);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          this.saving = false;
        });
      this.$emit("update-file", this.data);
      this.asset = null;
    },
  },

  watch: {
    snapshot() {
      if (this.snapshot) {
        this.data = this.snapshot;

        this.editDescription = this.data.description;

        if (!this.data.url) {
          this.loading = false;
          return;
        }

        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(this.data.url);

        fileRef
          .getMetadata()
          .then((metadata) => {
            fileRef.getDownloadURL().then((url) => {
              this.file = {
                name: metadata.name,
                type: metadata.contentType,
                size: metadata.size,
                url: url,
              };
            });
            this.loading = false;
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.asset = null;
      }
    },
  },
};
</script>

<style scoped></style>
