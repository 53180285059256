<template>
  <div v-click-outside="closePanel">
    <ais-instant-search :search-client="searchClient" :index-name="indexName">
      <div @click="expandPanel">
        <!-- Custom SearchField  -->
        <ais-search-box>
          <template v-slot="{ currentRefinement, refine }">
            <input
              type="search"
              placeholder="Buscar"
              class="field"
              :class="expanded ? 'expand' : ''"
              :value="currentRefinement"
              @input="refine($event.currentTarget.value)"
            />
          </template>
        </ais-search-box>
      </div>
      <!-- Resultado dos filtros com valor inicial false -->
      <v-card v-if="expanded" class="search-card">
        <ais-hits>
          <template v-slot="{ items }">
            <v-list dense>
              <!-- Tabs com diferentes opçoes de filtro -->
              <v-tabs>
                <v-tab
                  v-for="(tabs, i) in Tabs"
                  :disabled="tabs.disabled"
                  :key="i"
                  @click="handleFilterChange(tabs.value)"
                  class="normal semibold"
                  >{{ tabs.text }}
                </v-tab>
              </v-tabs>
              <v-divider></v-divider>

              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header class="grey lighten-5">
                    <span class="medium-text semibold">Filters</span>
                    <v-spacer></v-spacer>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <div
                      v-if="indexName === 'assets'"
                      class="search-panel__filters"
                    >
                      <span class="medium-text light">Tipo</span>
                      <ais-refinement-list attribute="type" />
                    </div>

                    <!-- <div
                      v-if="indexName === 'bundles'"
                      class="search-panel__filters"
                    >
                      <span class="medium-text light">Domain</span>
                      <ais-refinement-list attribute="title" />
                    </div> -->
                    <div
                      class="search-panel__filters d-flex"
                      v-if="indexName === 'questions'"
                    >
                      <div>
                        <h5 class="medium-text light">Tipo</h5>
                        <ais-refinement-list attribute="model_id" />
                      </div>
                      <div class="ml-8">
                        <h5 class="medium-text light">Domain</h5>
                        <ais-refinement-list attribute="domain" />
                      </div>
                    </div>
                  </v-expansion-panel-content>
                  <v-divider class="grey lighten-2"></v-divider>
                </v-expansion-panel>
              </v-expansion-panels>

              <!-- Lista sendo renderizada -->
              <ais-stats class="ma-3 normal thin">
                <template v-slot="{ nbPages, nbHits }">
                  <span class="normal gray-300">
                    {{ nbHits | formatNumber }} Resultados encontrado.
                  </span>
                </template>
              </ais-stats>

              <v-virtual-scroll height="300" item-height="35" :items="items">
                <template v-slot:default="{ item }">
                  <v-list-item @click="onItemSelected(item)" class="info-card">
                    <v-list-item-icon>
                      <v-icon small color="success"
                        >mdi-folder-star-multiple
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="
                          item.description ? item.description : item.title
                        "
                        class="medium-text bold grey-300"
                      ></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content class="ml-3 text-center">
                      <v-list-item-title
                        class="normal text-capitalize thin grey-200"
                      >
                        {{
                          item.type ? item.type : item.status
                        }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-list>
          </template>
        </ais-hits>
        <v-divider></v-divider>

        <ais-configure
          :attributesToSnippet="['description:50']"
          snippetEllipsisText="…"
          :hits-per-page.camel="20"
        />

        <ais-pagination :total-pages="6" />
      </v-card>
    </ais-instant-search>
  </div>
</template>
<script>
import { gsap } from "gsap";
import "instantsearch.css/themes/satellite-min.css";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
export default {
  data() {
    return {
      searchClient: instantMeiliSearch(
        sessionStorage.getItem("url"),
        sessionStorage.getItem("token")
      ),
      expanded: false,
      setFilters: true,
      benched: 0,
      pages: 10,
      indexName: "assets",
      Tabs: [
        { text: "Arquivos", value: "assets" },
        { text: "Planetas", value: "bundles" },
        { text: "Questões", value: "questions" },
      ],
    };
  },
  methods: {
    handleFilterChange(e) {
      this.indexName = e;
    },
    async closePanel() {
      if (this.expanded == true) {
        await gsap.fromTo(
          ".search-card",
          { opacity: 1 },
          { opacity: 0, duration: 0.2 }
        );
        this.expanded = false;
        gsap.fromTo(".field", { width: 480 }, { width: 175, duration: 0.2 });
      }
      this.handleFilterChange("assets");
    },

    async expandPanel() {
      if (this.expanded != true) {
        this.expanded = true;
        await gsap.fromTo(
          ".field",
          { width: 175 },
          { width: 580, duration: 0.3 }
        );
        gsap.fromTo(
          ".search-card",
          { opacity: 0 },
          { opacity: 1, duration: 0.3 }
        );
      }
    },

    onItemSelected(e) {
      console.log(e);
      if (this.indexName === "bundles") {
        this.$router.push(
          `/domain/${e.domain_code}/atividades_digitais/${e.id}`
        );
      }
      if (this.indexName === "questions") {
        this.$router.push(`/domain/${e.domain}/qt/${e.id}`);
      }
      if (this.indexName === "assets") {
        console.log("assets", e);
        this.$router.push(`/fl/details/${e.id}`);
      }
      this.closePanel();
    },
  },
};
</script>
<style scoped>
.field {
  width: 155px;
  position: absolute;
  top: 10px;
  right: 90px;

  text-indent: 15px;
  border-radius: 10px;
}
.expand {
  background: white !important;
  z-index: 10 !important;
  height: 40px;
  box-shadow: -106px -25px 36px 32px rgba(255, 255, 255, 0.75) !important;
  -webkit-box-shadow: -106px -25px 36px 32px rgba(255, 255, 255, 0.75) !important;
  -moz-box-shadow: -106px -25px 36px 32px rgba(255, 255, 255, 0.75) !important;
}
.search-card {
  position: absolute;
  right: 90px;
  top: 50px;
  border-radius: 10px !important;
  min-height: 375px !important;
  max-height: 875px;
  width: 580px;
  opacity: 0;
}
::-webkit-scrollbar {
  display: none !important;
}

input[type="search"] {
  padding: 0px;
  border: 0;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.03);
  border: 1px solid grey;
  font-family: inter !important;
  font-weight: 400;
  font-size: 16px;
  height: 35px;
  margin-top: 2px;
}
input:focus {
  outline: none;
  border: 1px solid #0f8fef;
}
</style>
