<template>
  <v-app>
    <Login v-if="!user.loggedIn" />
    <v-main v-else-if="user.loggedIn" class="grey lighten-5">
      <Navigation />
      <div class="pa-6">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
        <Alert :bar="alert" />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "@/components/Bar/TheNavigationBar.vue";
import Alert from "./components/Skeleton/Alert.vue";
import Login from "./components/Pages/Login";
import { mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({}),

  components: {
    Login,
    Navigation,
    Alert,
  },

  computed: {
    ...mapGetters(["user", "alert"]),
  },

  watch: {
    $route(to) {
      document.title = to.meta.title || "EduLab";
    },
  },
};
</script>

<style>
@import url("./assets/css/Global.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-enter-active {
  animation: fade-in 0.2s ease;
}

.fade-leave-active {
  animation: fade-out 0.2s ease;
}
</style>
