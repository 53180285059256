<template>
  <v-card
    class="mx-auto card-info rounded-lg"
    outlined
    :class="this.$route.path === '/' ? null : 'info-card'"
    :to="
      this.$route.path === '/'
        ? null
        : { name: 'da-planet', params: { id: planet.id } }
    "
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="normal light grey--text mb-4">
          {{ planet.updated_at | formatDate }}
        </div>
        <v-list-item-title class="larger bold mb-1">
          {{ planet.title }}
        </v-list-item-title>
        <v-list-item-subtitle class="medium-text semibold"
          >Nível {{ planet.level }} • Posição
          {{ planet.position }}</v-list-item-subtitle
        >
        <v-list-item-subtitle class="normal">{{
          planet.status
        }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar tile size="100" :src="src">
        <v-img contain :src="src"></v-img>
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn outlined rounded text disabled> N Questões </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import firebase from "firebase";
export default {
  name: "PlanetCard",

  props: {
    planet: {
      type: Object,
    },
  },

  data: () => ({
    src: null,
    axisName: "EIXO TEMPORARIO",
  }),

  created() {
    if (!this.planet.avatar) return;
    const storageRef = firebase.storage().ref(this.planet.avatar);
    storageRef.getDownloadURL().then((url) => {
      this.src = url;
    });
  },
};
</script>

<style scoped>
.card-info {

  height: 185px;
}
</style>
