<template>
    <div id="app">
        <div class="m-flexContainer">
            <div class="a-heroImage"></div>

            <div align="center" class="m-formContainer">
                <div class="d-inline-block">
                    <img
                            src="@/assets/logo.svg"
                            height="93"
                            alt="EduLab Logo">

                    <div id="firebaseui-auth-container"></div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import firebase from 'firebase';
    import * as firebaseui from "firebaseui"
    import "firebaseui/dist/firebaseui.css";

    export default {
        name: "Login",

        mounted() {
            const uiConfig = {
                signInOptions: [
                    {
                        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                        fullLabel: "Entrar com Google"
                    }
                ],
                signInFlow: 'popup',
            };
            const ui = new firebaseui.auth.AuthUI(firebase.auth());
            ui.start('#firebaseui-auth-container', uiConfig);
        },

        destroyed(){
            const ui = firebaseui.auth.AuthUI.getInstance();
            ui.delete();
        }
    }
</script>

<style scoped lang="scss">
    @mixin transition-ease {
        transition: all 0.5s ease-in-out;
    }

    @mixin flex($flex-flow, $justify-content: center, $align-items: center) {
        display: flex;
        flex-flow: $flex-flow;
        justify-content: $justify-content;
        align-items: $align-items;
    }

    *,
    *:before,
    *:after {
        box-sizing: border-box;

        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    * {
        padding: 0;
        margin: 0;
        border: 0;
    }

    .m-flexContainer {
        @include flex(row wrap, flex-start, center);
    }

    .m-formContainer {
        flex: 1;
        min-height: 100vh;

        padding: 173px 55px 55px;

        background-color: #f7f7f7;

        @media screen and (max-width: 450px) {
            padding: 173px 20px 55px;
        }
    }

    .a-heroImage {
        width: calc(100% - 560px);
        height: 100vh;

        background-image: url('~@/assets/login_bg.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @media screen and (max-width: 850px) {
            width: 0;
            background: none;
        }
    }
</style>