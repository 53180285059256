<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <div class="pa-4">
            <div class="d-flex justify-space-between">
              <p class="large bold">{{ cardTitle }}</p>
              <div v-if="question.updated_at" class="medium-text">
                <span class="bold">Atualizado em: </span>
                <span class="light"
                >{{ question.updated_at | formatDate }}
                </span>
              </div>
            </div>
            <div>
              <p class="bold">
                <a :href="`/domain/PORTUGUESE/atividades_digitais/${question.planet_id}`">
                  Planeta: {{ bundleTitle }} planetId: {{ question.planet_id }}
                </a>
              </p>
            </div>
            <v-form ref="form">
              <v-row>
                <TextField
                    cols="4"
                    label="Titulo"
                    :model="question.title"
                    @input="question.title = $event"
                    rules="true"
                />
                <SelectField
                    cols="4"
                    @input="question.level = $event"
                    :model="question.level"
                    :fields="Level"
                />
                <SelectField
                    cols="4"
                    @input="question.status = $event"
                    :model="question.status"
                    :fields="Status"
                />
                <TextField
                    cols="12"
                    label="Descrição"
                    :model="question.description"
                    @input="question.description = $event"
                    rules="true"
                />

                <v-col cols="12">
                  <v-autocomplete
                      dense
                      v-model="question.axis_id"
                      :items="axis"
                      outlined
                      hide-no-data
                      item-text="name"
                      item-value="id"
                      label="Eixo:"
                      placeholder="Comece a digitar para buscar"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <!-- Modelo -->
        <v-card class="pa-4" outlined>
          <p class="large bold mb-4">Modelo</p>
          <Skeleton v-if="loadingModels" number="3"/>
          <v-slide-group
              v-else
              v-model="modelPosition"
              class="pa-4"
              center-active
              show-arrows
          >
            <v-slide-item
                v-for="model in models"
                :key="model.id"
                v-slot:default="{ active, toggle }"
            >
              <div>
                <v-card
                    :img="model.url"
                    class="ma-4"
                    height="288"
                    width="432"
                    contain
                    @click="selectModel(model, toggle)"
                >
                  <v-row class="fill-height" align="center" justify="center">
                    <v-scale-transition>
                      <v-icon
                          v-if="active"
                          color="primary"
                          size="48"
                          v-text="'mdi-checkbox-marked-circle'"
                      >
                      </v-icon>
                    </v-scale-transition>
                  </v-row>
                </v-card>

                <div align="center">{{ model.id }}</div>
                <div align="center">
                  <v-btn
                      v-if="model.doc_link"
                      class="ma-2"
                      text
                      icon
                      :href="model.doc_link"
                      target="_blank"
                      color="blue lighten-2"
                  >
                    <v-icon>mdi-jira</v-icon>
                  </v-btn>
                  <v-btn
                      v-if="model.video_link"
                      class="ma-2"
                      text
                      icon
                      :href="model.video_link"
                      target="_blank"
                      color="red lighten-2"
                  >
                    <v-icon>mdi-youtube</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-card>

        <v-card v-if="question.model_id != null" class="mt-8 pa-4" outlined>
          <p class="large bold">Regras</p>

          <RuleForm
              v-for="(rule, i) in question.rules"
              :key="i"
              :rule="rule"
              ref="form"
          >
          </RuleForm>

          <div class="d-flex justify-center">
            <Button
                @ClickEvent="showRuleDialog = true"
                label="Adicionar Regra"
                tipo="secondary"
            />
          </div>
        </v-card>

        <v-card v-if="question.model_id != null" class="mt-8 pa-4" outlined>
          <p class="large bold">Enunciados</p>

          <TitleForm
              ref="form"
              v-for="title in question.titles"
              :key="title.id"
              :title="title"
          >
            <div class="d-flex justify-end">
              <v-btn icon v-if="!title.required" @click="deleteTitle(title)">
                <v-icon> mdi-close</v-icon>
              </v-btn>
            </div>
          </TitleForm>

          <div class="d-flex justify-center">
            <v-btn @click.stop="showTitleDialog = true" outlined>
              Adicionar Enunciado
            </v-btn>
          </div>
        </v-card>

        <v-card v-if="question.model_id != null" class="mt-8 pa-4" outlined>
          <h2>Alternativas</h2>

          <OptionForm
              v-for="option in question.options"
              :key="option.id"
              :option="option"
          >
            <div class="d-flex justify-end">
              <v-btn outlined @click="deleteOption(option)">
                <v-icon> mdi-delete</v-icon>
              </v-btn>
            </div>
          </OptionForm>

          <div class="d-flex justify-center">
            <v-btn @click="addOption()" outlined> Adicionar alternativa</v-btn>
          </div>
        </v-card>
      </v-col>

      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <Button
              v-if="id != null"
              @ClickEvent="deleteQuestion"
              label="Deletar"
              tipo="error"
              class="mr-4"
              :disabled="saving"
          />
          <Button
              :loading="saving"
              @ClickEvent="save()"
              label="Salvar"
              tipo="primary"
              class="mr-4"
          />
        </v-col>
      </v-row>
    </v-row>

    <CardTitleDialog
        @create-title="addTitle"
        :showDialog="showTitleDialog"
        v-model="showTitleDialog"
    >
    </CardTitleDialog>

    <CardRuleDialog
        @create-rule="createRule"
        :showDialog="showRuleDialog"
        v-model="showRuleDialog"
    >
    </CardRuleDialog>
  </v-container>
</template>

<script>
import firebase from "firebase";
import OptionForm from "@/components/Pages/Questions/OptionForm.vue";
import RuleForm from "@/components/Pages/Questions/RuleForm";
import TitleForm from "@/components/Pages/Questions/TitleForm";
import CardTitleDialog from "../../components/Dialogs/CardTitleDialog";
import CardRuleDialog from "../../components/Dialogs/CardRuleDialog";

export default {
  name: "QuestionForm",

  components: {
    TitleForm,
    RuleForm,
    OptionForm,
    CardTitleDialog,
    CardRuleDialog,
  },

  props: {
    id: {
      type: String,
      default: null,
    },
  },

  computed: {
    cardTitle() {
      return this.id == null ? "Nova Questão" : `Editando ${this.id}`;
    }
  },

  data() {
    return {
      loadingModels: false,
      saving: false,
      axis: [],
      models: [],
      Level: {
        label: "Nível",
        answers: [0, 1, 2, 3, 4, 5],
      },
      Status: {
        label: "Status",
        answers: ["RASCUNHO", "DESENVOLVIMENTO", "PRODUÇÃO", "ARQUIVADO"],
        rules: true,
      },

      question: {
        id: null,
        axis_id: null,
        model_id: null,
        title: null,
        description: null,
        level: 0,
        status: "",
        domain: this.$route.params.domain,

        titles: [],
        options: [],
        rules: [],
        created_at: null,
        updated_at: null,
      },

      modelPosition: null,
      modelSelected: null,

      showTitleDialog: false,
      showRuleDialog: false,
      bundleTitle: ""
    };
  },

  methods: {
    async loadAxis() {
      const db = firebase.firestore();
      let allAxis = await db.collection("axis").get();
      for (const ax of allAxis.docs) {
        this.axis.push(ax.data());
      }
    },

    async loadModels() {
      const data = {
        collection: "models",
        orderBy: "id",
        limit: 50,
      };
      const res = await this.$getData(data);
      for (const md of res) {
        let model = md.data();

        if (model.status === "ARQUIVADO") {
          continue;
        }

        if (model.image) {
          let storageRef = firebase.storage().ref(model.image);
          model.url = await storageRef.getDownloadURL();
        }

        this.models.push(model);
      }

      //todo: sort models by id
    },

    selectModel(model, toggle) {
      if (
          this.question.model_id === null ||
          confirm(
              "Será preciso cadastrar enunciados e regras novamente. Tem certeza?"
          )
      ) {
        this.modelSelected = model;
        this.question.model_id = model.id;

        this.question.rules = [];
        this.question.titles = [];

        this.question.rules.push(...model.rules);
        this.question.titles.push(...model.titles);

        for (const [position, title] of this.question.titles.entries()) {
          title.file_id = null;
          title.position = position;
          title.placeholder = title.description;
        }

        toggle();
      }
    },

    addOption() {
      this.question.options.push({
        id: Math.floor(Math.random() * 1000),
        description: null,
        image_id: null,
        sound_id: null,
        isCorrect: false,
      });
    },

    addTitle(title) {
      this.question.titles.push(title);
    },

    createRule(rule) {
      this.question.rules.push(rule);
    },

    deleteOption(option) {
      const index = this.question.options.indexOf(option);
      if (index > -1) {
        this.question.options.splice(index, 1);
      }
    },

    deleteTitle(position) {
      this.question.titles.splice(position, 1);
    },

    deleteQuestion() {
      if (confirm("Meu Deus! 😱 Não tem volta!!! Tem certeza?")) {
        this.saving = true;

        let db = firebase.firestore();
        let docRef = db.collection("questions").doc(this.id);
        docRef.delete().then(() => {
          this.$router.push({name: "question-list"});
        });
      }
    },

    save() {
      this.saving = true;
      let db = firebase.firestore();
      let docRef = db.collection("questions");

      if (this.question.options) {
        this.question.options.forEach((element) => {
          element.position = parseInt(element.position);
        });
      }

      if (this.id) {
        this.question.updated_at =
            firebase.firestore.FieldValue.serverTimestamp();
        docRef
            .doc(this.id)
            .set(this.question)
            .then(() => {
              this.$store.commit("SET_ALERT", "Questão Salva");
              this.$router.push({name: "question-list"});
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
              this.saving = false;
            });
      } else {
        const doc = docRef.doc();
        this.question.id = doc.id;
        this.question.created_at =
            firebase.firestore.FieldValue.serverTimestamp();

        doc
            .set(this.question)
            .then(() => {
              this.$store.commit("SET_ALERT", "Questão criada!");
              this.$router.push({name: "question-list"});
              this.$store.commit("SET_ALERT", "Questão Salva");
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
              this.saving = false;
            });
      }
    },

    getData() {
      let db = firebase.firestore();
      let docRef = db.collection("questions").doc(this.id);
      docRef.get().then((doc) => {
        if (doc.exists) {
          let data = doc.data();

          data.options.forEach((el, i) => {
            el.id = Math.floor(Math.random() * 1000 + i);
          });

          data.titles.forEach((el, i) => {
            el.id = Math.floor(Math.random() * 1000 + i);
          });
          this.question = data;
          db.collection("bundles").doc(this.question.planet_id).get().then((planet) => {
            if (planet.exists) this.bundleTitle = planet.data().title
          })
        } else {
          this.$router.push({name: "question-list"});
        }
      });
    },
    setModel() {
      if (this.id) {
        for (let position in this.models) {
          const model = this.models[position];
          if (model.id === this.question.model_id) {
            this.modelPosition = parseInt(position);
            this.modelSelected = model;
            break;
          }
          this.loadingModels = false;
        }
      } else {
        this.loadingModels = false;
      }
    },
  },
  watch: {
    id() {
      this.getData();
    },
  },
  async created() {
    this.loadingModels = true;
    this.loadAxis();
    if (this.id) {
      this.getData();

    }

    await this.loadModels();
    this.setModel();
  },
};
</script>

<style scoped></style>
