var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[(_vm.dialogData)?_c('v-dialog',{attrs:{"width":"700","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"width":"700","height":"600px"}},[_c('v-card-title',{staticClass:"large bold"},[_vm._v(" Escolha em qual planeta a questão será "),(_vm.action === 'Clonando Questão')?_c('span',{staticClass:"ml-2"},[_vm._v(" clonada")]):_c('span',{staticClass:"ml-2"},[_vm._v(" transferida")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-subtitle',{staticClass:"pb-10 pt-1 normal"},[_c('span',{staticClass:"bold normal"},[_vm._v("Questão:")]),_vm._v(" "+_vm._s(_vm.dialogData.ToBeCloned.description)+" ")]),_c('v-virtual-scroll',{attrs:{"bench":_vm.planets.lenght,"items":_vm.planets,"height":"470","item-height":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"medium-text bold"},[_vm._v(" "+_vm._s(item.title ? item.title : "Título não definido"))]),_c('v-list-item-subtitle',[_c('span',{staticClass:"bold"},[_vm._v("Domain: ")]),_vm._v(_vm._s(item.domain_code)+" | "),_c('span',{staticClass:"bold"},[_vm._v(" Status: ")]),_vm._v(" "+_vm._s(item.status ? item.status : "Status não definido")+" ")]),_c('v-list-item-subtitle',[_vm._v(" LEVEL: "+_vm._s(item.level)+" ")])],1),_c('v-list-item-action',[_c('v-btn',{staticClass:"primary text-capitalize",attrs:{"to":{
                  name: 'da-question-edit',
                  params: {
                    action: _vm.action,
                    planetId: item.id,
                    id: _vm.dialogData.ToBeCloned.id,
                    planeta: _vm.dialogData.Planet,
                  },
                }}},[_vm._v("Selecionar")])],1)],1),_c('v-divider')]}}],null,false,1541697856)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }