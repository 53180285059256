<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-app>
    <v-card :loading="loading" outlined>
      <v-card-title class="large">
        {{ cardTitle }}

        <v-spacer></v-spacer>
        <!--                <div v-if="planet.updated_at" class="text-overline">Atualizado em: {{ planet.updated_at.toDate() }}</div>-->
        <div>
          <v-col class="d-flex justify-end">
            <Button
              label="Sinc Dev"
              :disabled="syncDev"
              @ClickEvent="requestSync(syncDev, 'test')"
              tipo="primary"
              class="ml-2"
            />

            <Button
              label="Sinc Prod"
              :disabled="syncProd"
              @ClickEvent="requestSync(syncProd, 'prod')"
              tipo="primary"
              class="ml-2"
            />

            <Button
              label="Sinc Escola (dev)"
              :disabled="isSyncingEscola"
              :loading="isSyncingEscola"
              @ClickEvent="requestSync(syncProd, 'escola')"
              tipo="primary"
              class="ml-2"
            />

            <Button
              label="Salvar"
              :loading="saving"
              :disabled="saving"
              @ClickEvent="save"
              tipo="primary"
              class="ml-2"
            />
          </v-col>
        </div>
      </v-card-title>
      <v-row>
        <v-col cols="12">
          <v-card-text>
            <span class="medium-text semibold"> Geral </span>
            <v-row>
              <v-col cols="3">
                <VueFileAgent
                  v-model="file"
                  ref="vueFileAgent"
                  maxSize="1MB"
                  helpText="Selecione arquivos ou arraste e solte aqui"
                  :multiple="false"
                  :deletable="true"
                  :meta="false"
                  :accept="'image/*'"
                  :compact="true"
                  :errorText="{
                    type: 'Arquivo de extensão não aceita. Apenas imagem é aceita.',
                    size: 'Arquivos não devem ultrapassar 15MB',
                  }"
                  style="background-color: gray"
                  @beforedelete="file = null"
                >
                </VueFileAgent>
              </v-col>
              <v-col cols="9">
                <v-form ref="form">
                  <v-row>
                    <TextField
                      cols="6"
                      label="Nome"
                      readonly="false"
                      :model="planet.title"
                      @input="planet.title = $event"
                      rules="true"
                    />
                    <TextField
                        cols="3"
                        label="BNCC"
                        readonly="false"
                        :model="planet.bncc"
                        @input="planet.bncc = $event"
                      />
                    <SelectField
                      cols="3"
                      :fields="Status"
                      :model="planet.status"
                      @input="planet.status = $event"
                      rules
                    />
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-autocomplete
                        dense
                        v-model="planet.axis_id"
                        :items="Axis"
                        outlined
                        hide-no-data
                        item-text="name"
                        item-value="id"
                        label="Eixo"
                      >
                      </v-autocomplete>
                    </v-col>
                    <SelectField
                      cols="4"
                      :fields="levels"
                      :model="planet.level"
                      @input="planet.level = $event"
                      rules
                    />
                    <TextField
                      cols="4"
                      label="Posição"
                      disabled
                      readonly="false"
                      :model="planet.position"
                      @input="planet.position = $event"
                    />
                  </v-row>
                </v-form>
              </v-col>
            </v-row>

            <v-card
              flat
              class="grey lighten-5 pa-2"
              v-if="planet.id"
              :loading="loadingQuestions"
            >
              <div class="pa-2 d-flex justify-space-between">
                <div>
                  <span
                    @click="questoes = !questoes"
                    :class="questoes ? 'active' : ''"
                    class="medium-text semibold tabs ml-4 mt-3"
                  >
                    Questões
                  </span>
                  <span
                    :class="!questoes ? 'active' : ''"
                    @click="questoes = !questoes"
                    class="medium-text semibold tabs ml-4 mt-3"
                  >
                    Assets
                  </span>
                </div>
                <div>
                  <v-btn
                    v-if="questoes"
                    fab
                    depressed
                    small
                    color="primary"
                    :to="{
                      name: 'da-question-create',
                      params: {
                        planetId: this.id,
                        action: actionCreate(),
                      },
                    }"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </div>

              <v-simple-table v-if="questoes">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">+</th>
                      <th class="text-left">TÍTULO</th>
                      <th class="text-left">DESCRIÇÃO</th>
                      <th class="text-left">MODELO</th>
                    </tr>
                  </thead>
                  <draggable
                    v-model="questions"
                    tag="tbody"
                    class="list-group"
                    ghost-class="ghost"
                    handle=".handle"
                    @change="sortQuestions = true"
                  >
                    <tr
                      v-for="question in questions"
                      :key="question.id"
                      class="list-group-item"
                    >
                      <td style="width: 0.1%">
                        <v-btn style="cursor: move" icon class="handle">
                          <v-icon>mdi-drag-horizontal</v-icon>
                        </v-btn>
                      </td>
                      <td class="normal">{{ question.title }}</td>
                      <td class="normal">
                        {{ question.description }}
                      </td>
                      <td class="normal semibold">{{ question.model_id }}</td>
                      <td style="width: 15%">
                        <v-row class="d-flex">
                          <v-btn
                            small
                            text
                            icon
                            @click="
                              HandleClone(
                                question,
                                (action = 'Transferindo Questão')
                              )
                            "
                          >
                            <v-icon small color="grey darken-3">
                              mdi-transfer
                            </v-icon>
                          </v-btn>
                          <v-btn
                            small
                            text
                            icon
                            @click="
                              HandleClone(
                                question,
                                (action = 'Clonando Questão')
                              )
                            "
                          >
                            <v-icon small color="green">
                              mdi-plus-circle-multiple-outline
                            </v-icon>
                          </v-btn>
                          <v-btn
                            small
                            text
                            icon
                            :to="{
                              name: 'da-question-edit',
                              params: {
                                action: 'Editando Questão',
                                planetId: id,
                                id: question.id,
                              },
                            }"
                          >
                            <v-icon color="blue" small> mdi-pencil </v-icon>
                          </v-btn>
                          <v-btn
                            small
                            text
                            icon
                            @click="deleteQuestion(question)"
                          >
                            <v-icon small color="red"> mdi-delete </v-icon>
                          </v-btn>
                        </v-row>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-simple-table>
              <div v-if="!questoes">
                <!-- questionsAssets -->
                <v-row class="mt-8">
                  <v-col
                    v-for="(file, i) in questionsAssets.slice().reverse()"
                    :key="i"
                    sm="6"
                    md="4"
                    lg="3"
                    xl="2"
                  >
                    <FileCard :snapshot="file" />
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <PlanetDialog
      :action="actionDialog"
      :dialog="dialog"
      :dialogData="dialogData"
      @close="closeDialog"
      :domain="domain"
    />
  </v-app>
</template>

<script>
import FileCard from "@/components/Cards/FileCard";
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";
import Vue from "vue";
import draggable from "vuedraggable";
import firebase from "firebase";
import { mapGetters } from "vuex";
import PlanetDialog from "../../components/Dialogs/PlanetPickerDialog.vue";

Vue.use(VueFileAgentStyles);

export default {
  name: "PlanetCreate",

  components: { draggable, PlanetDialog, FileCard },

  props: {
    id: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapGetters,
    cardTitle() {
      return this.id == null
        ? "Novo Planeta"
        : `Editar Planeta ${this.planet.title ? this.planet.title : ""}`;
    },
    domain() {
      return this.$route.params.domain.toUpperCase();
    },
  },

  data: () => ({
    loading: true,
    questoes: true,
    dialog: false,
    dialogData: null,
    loadingQuestions: false,
    saving: false,
    sortQuestions: false,
    tests: "",
    Axis: [],
    questionsAssets: [],
    Nome: {
      label: "Nome",
      value: "",
    },
    Status: {
      label: "Status",
      answers: [
        "RASCUNHO",
        "PRODUÇÃO PEDAGÓGICO",
        "REVISÃO PEDAGÓGICA 1",
        "AJUSTE PÓS REVISÃO PEDAGÓGICA",
        "REVISÃO EXTERNA",
        "AJUSTE PÓS REVISÃO EXTERNA",
        "REVISÃO PEDAGÓGICA 2",
        "LIBERADO PEDAGÓGICO",
        "PRODUÇÃO AUDIOVISUAL",
        "LIBERADO AUDIOVISUAL",
        "VISTAS PEDAGÓGICO",
        "REVISÃO REJEITADA",
        "REVISÃO APROVADA",
        "TESTES E AJUSTES",
        "PRODUÇÃO",
        "ARQUIVADO",
      ],
      selected: "",
    },

    levels: {
      label: "Nível",
      answers: [0, 1, 2, 3, 4, 5, 6],
    },
    status: [
      "RASCUNHO",
      "PRODUÇÃO PEDAGÓGICO",
      "REVISÃO PEDAGÓGICA 1",
      "AJUSTE PÓS REVISÃO PEDAGÓGICA",
      "REVISÃO EXTERNA",
      "AJUSTE PÓS REVISÃO EXTERNA",
      "REVISÃO PEDAGÓGICA 2",
      "LIBERADO PEDAGÓGICO",
      "PRODUÇÃO AUDIOVISUAL",
      "LIBERADO AUDIOVISUAL",
      "VISTAS PEDAGÓGICO",
      "REVISÃO REJEITADA",
      "REVISÃO APROVADA",
      "TESTES E AJUSTES",
      "PRODUÇÃO",
      "ARQUIVADO",
    ],

    file: null,

    planet: {
      id: null,
      min_bundle_version: 47,
      title: null,
      avatar: null,
      axis_id: null,
      status: "RASCUNHO",
      domain_code: null,
      level: 1,
      position: 1,
      enable: true,
      updated_at: null,
      bncc: null,
    },

    questions: [],

    syncDev: false,
    syncProd: false,
    isSyncingEscola: false,
  }),

  methods: {
    actionCreate() {
      `Criar nova questão ${this.planet.title ? this.planet.title : ""}`;
      if (this.planet.title) {
        return `Criar nova questão no planeta ${this.planet.title}`;
      } else {
        return "Criar nova questão";
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    HandleClone(q, action) {
      this.dialog = true;
      this.actionDialog = action;
      const data = {
        ToBeCloned: q,
        Planet: this.planet.title,
      };
      this.dialogData = data;
    },
    async loadAxis() {
      const db = firebase.firestore();

      let allAxis = await db
        .collection("axis")
        .where("domain_code", "==", this.domain)
        .get();
      for (const ax of allAxis.docs) {
        const data = ax.data();
        this.Axis.push(data);
      }
    },

    //Titles
    deleteQuestion(question) {
      if (confirm("Meu Deus! 😱 Não tem volta!!! Tem certeza?")) {
        let db = firebase.firestore();
        let docRef = db.collection("questions").doc(question.id);
        docRef.delete().then(() => {
          const index = this.questions.indexOf(question);
          this.questions.splice(index, 1);
        });
      }
    },
    //EndQuestions
    uploadImage(doc) {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`planets/${this.planet.id}`);
      const uploadTask = fileRef.put(this.file.file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.file.progress(progress);
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          doc.update({
            avatar: uploadTask.snapshot.ref.fullPath,
          });
          this.$router.push({ name: "planet-list" });
        }
      );
    },

    save() {
      if (this.$refs.form.validate()) {
        if (!this.file) {
          alert("Arquivo é obrigatório para criar um novo planeta");
        } else {
          this.saving = true;

          this.planet.updated_at =
            firebase.firestore.FieldValue.serverTimestamp();
          this.planet.domain_code = this.domain;

          let db = firebase.firestore();
          let docRef = db.collection("bundles");

          if (this.id) {
            const doc = docRef.doc(this.id);
            doc
              .set(this.planet)
              .then(() => {
                if (this.file.file) this.uploadImage(doc);
                else this.$router.push({ name: "planet-list" });
              })
              .catch((error) => {
                console.error("Error adding document: ", error);
                this.saving = false;
              });

            this.saveQuestionsPosition();
          } else {
            const doc = docRef.doc();
            this.planet.id = doc.id;
            doc
              .set(this.planet)
              .then(() => {
                console.log(this.file);
                if (this.file.file) this.uploadImage(doc);
                else this.$router.push({ name: "planet-list" });
              })
              .catch((error) => {
                console.error("Error adding document: ", error);
                this.saving = false;
              });
          }
        }
      }
    },

    loadQuestions() {
      this.loadingQuestions = true;

      const db = firebase.firestore();
      db.collection("questions")
        .where("planet_id", "==", this.id)
        .orderBy("position")
        .get()
        .then((querySnapshot) => {
          this.questions = [];
          this.questionsAssets = [];

          querySnapshot.docs.forEach((doc) => {
            this.questions.push(doc.data());
            const data = doc.data();

            data.titles.forEach((element) => {
              this.getAssets(element);
            });

            data.options.forEach((element) => {
              this.getAssets(element);
            });
          });

          this.loadingQuestions = false;
        });
    },

    getAssets(e) {
      if (e.image_id || e.sound_id || e.file_id) {
        const db = firebase.firestore();
        db.collection("assets")
          .where("id", "==", e.image_id || e.sound_id || e.file_id)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {
                const data = doc.data();
                var index = this.questionsAssets.findIndex(function (el) {
                  return el.id === data.id;
                });
                if (index !== -1) {
                  return;
                } else {
                  this.questionsAssets.push(data);
                }
                this.questionsAssets.sort((a, b) => a.size - b.size);
              });
            }
          });
      }
    },

    saveQuestionsPosition() {
      let db = firebase.firestore();

      for (let position in this.questions) {
        let docRef = db.collection("questions");
        const doc = docRef.doc(this.questions[position].id);
        doc.update({
          position: parseInt(position),
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
      this.sortQuestions = false;
    },

    requestSync(bool, env) {
      if (env === "prod") {
        this.syncProd = true;
      } else if (env === "escola") {
        return this.syncEscola()
      } else {
        this.syncDev = true;
      }

      let db = firebase.firestore();
      let docRef = db.collection("request_sync_da");
      const doc = docRef.doc();

      const request = {
        id: doc.id,
        env: env,
        bundle_id: this.planet.id,
        domain_code: this.domain,
        req_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        status: "PENDING",
        userId: firebase.auth().currentUser.uid,
      };

      doc
        .set(request)
        .then(() => {
          bool = true;
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },

    getData() {
      let db = firebase.firestore();
      let docRef = db.collection("bundles").doc(this.id);

      docRef.get().then((doc) => {
        if (doc.exists) {
          this.planet = doc.data();

          const storageRef = firebase.storage().ref();
          const fileRef = storageRef.child(`planets/${this.planet.id}`);

          fileRef
            .getMetadata()
            .then((metadata) => {
              fileRef.getDownloadURL().then((url) => {
                this.file = {
                  name: metadata.name,
                  type: metadata.contentType,
                  size: metadata.size,
                  url: Array.isArray(url) ? url[0] : url,
                };
              });
            })
            .catch(function (error) {
              // Uh-oh, an error occurred!
              console.log(error);
            });

          this.loading = false;
        } else {
          this.$router.push({ name: "planet-list" });
        }
      });
    },

    async syncEscola() {

      this.isSyncingEscola = true
      let storage = firebase.storage();
      const questionsWithUrls = await Promise.all(this.questions.map(async q => {

        const titles = await Promise.all(
          q.titles.map(async t => {
            if (t.file_id) {
              const asset = this.questionsAssets.find(a => a.id === t.file_id)

              if (asset) {
                const file_url_origin = await storage.ref(asset.url).getDownloadURL()

                return {
                  ...t,
                  file_url: t.file_url ?? null,
                  file_url_origin
                }
              }
            }
            return t
          })
        )

        const options = await Promise.all(q.options.map(async o => {
          let image_url_origin,
            sound_url_origin = null

          if (o.image_id) {
            const asset = this.questionsAssets.find(a => a.id === o.image_id)
            if (asset) {
              image_url_origin = await storage.ref(asset.url).getDownloadURL()
            }
          }

          if (o.sound_id) {
              sound_url_origin = await storage.ref(`assets/${o.sound_id}`).getDownloadURL()
          }

          return {
            ...o,
            image_url_origin,
            image_url: o.image_url ?? null,
            sound_url_origin,
            sound_url: o.sound_url ?? null,
          }
        }))

        return {
          ...q,
          titles,
          options
        }
      
      }))

      const jason = {
        ...this.planet,
        avatar_url: null,
        avatar_url_origin: this.file.url,
        questions: questionsWithUrls,
      }

      
      const SYNC_URL = "https://syncescola-3njxnh3tta-uc.a.run.app"

      const response = await fetch(SYNC_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jason),
      })

      if (!response.ok) {
        alert("Erro ao sincronizar com EduEdu Escola")
      }

      console.log(await response.json())

      this.isSyncingEscola = false
    }
  },
  watch: {
    id() {
      this.loadQuestions();
      this.getData();
    },
  },
  async created() {
    await this.loadAxis();

    if (this.id) {
      this.loadQuestions();
      this.getData();
    } else {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}
.tabs {
  cursor: pointer;
  padding: 8px;
  border-radius: 5px;
}
.active {
  background: rgb(133, 133, 243);
  color: white !important;
}
</style>
