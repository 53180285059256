<template>
  <v-app class="pa-4 grey lighten-5">
    <ais-instant-search
      name="assets:updated_at:asc"
      :search-client="searchClient"
      index-name="assets:updated_at:desc"
    >
      <div class="text-center">
        <ais-autocomplete>
          <template v-slot="{ currentRefinement, refine }">
            <input
              type="search"
              placeholder="Buscar arquivos"
              class="fields"
              :value="currentRefinement"
              @input="refine($event.currentTarget.value)"
            />
          </template>
        </ais-autocomplete>

        <ais-refinement-list attribute="type" class="mb-2" searchable show-more>
          <template v-slot="{ items, refine, createURL }">
            <v-layout class="list-wrapper mt-3">
              <v-flex
                xs2
                class="list"
                v-for="item in items"
                :key="item.value"
                @click.prevent="refine(item.value)"
              >
                <a
                  class="list-font"
                  :href="createURL(item)"
                  :style="{ fontWeight: item.isRefined ? 'bold' : '' }"
                >
                  <ais-highlight attribute="item" :hit="item" />
                  ({{ item.count.toLocaleString() }})
                </a>
              </v-flex>
            </v-layout>
          </template>
        </ais-refinement-list>

        <v-divider class="mt-8"></v-divider>
        <v-layout wrap class="d-flex justify-center">
          <v-flex sm6 md3 class="d-flex justify-start">
            <ais-stats class="mt-4 normal thin">
              <template v-slot="{ nbPages, nbHits }">
                <span class="normal gray-300">
                  <span class="bold">{{ nbHits | formatNumber }} </span>
                  Resultados encontrado.
                </span>
              </template>
            </ais-stats>
          </v-flex>
          <v-flex sm8 md6>
            <ais-configure
              :attributesToSnippet="['description:50']"
              snippetEllipsisText="…"
              :hits-per-page.camel="20"
            >
              <div
                slot-scope="{ searchParameters, refine }"
                class="d-flex justify-center"
              >
                <v-card flat class="range-wrapper transparent">
                  <v-form class="d-flex">
                    <v-text-field
                      outlined
                      class="mt-2 mr-1 ml-1"
                      label="Data inicial"
                      dense
                      v-mask="'##/##/####'"
                      placeholder="dd/mm/aaaa"
                      v-model="minNumber"
                    />
                    <v-text-field
                      outlined
                      v-mask="'##/##/####'"
                      class="mt-2 mr-1 ml-1"
                      dense
                      placeholder="dd/mm/aaaa"
                      label="Data final"
                      v-model="maxNumber"
                      :error-messages="errorMsg"
                    />
                    <v-btn
                      height="40px"
                      outlined
                      :disabled="
                        !disabledBtn &&
                        maxNumber &&
                        minNumber &&
                        maxNumber.length === 10 &&
                        minNumber.length === 10
                          ? false
                          : true
                      "
                      class="mt-2 mr-1 ml-1"
                      @click="
                        () => {
                          const filters = [`history ${min} TO ${max}`]
                            .filter((filter) => filter)
                            .join(' AND ');
                          refine({
                            ...searchParameters,
                            filters,
                          });
                        }
                      "
                    >
                      Filtrar
                    </v-btn>
                    <v-btn
                      text
                      class="mt-2 mr-1 ml-1"
                      height="40px"
                      @click="
                        () => {
                          const filters = [`size > 0`]
                            .filter((filter) => filter)
                            .join(' AND ');
                          refine({
                            ...searchParameters,
                            filters,
                          });
                          minNumber = null;
                          maxNumber = null;
                        }
                      "
                    >
                      <v-icon>mdi-filter-off-outline</v-icon>
                    </v-btn>
                  </v-form>
                </v-card>
              </div>
            </ais-configure>
          </v-flex>
          <v-flex sm4 md3 class="d-flex justify-end">
            <ais-sort-by
              class="mt-2"
              :items="[
                {
                  value: 'assets:updated_at:desc',
                  label: 'Atualização: Decrescente',
                },
                {
                  value: 'assets:updated_at:asc',
                  label: 'Atualização: Crescente',
                },
                {
                  value: 'assets:created_at:desc',
                  label: 'Data de criação: Decrescente',
                },
                {
                  value: 'assets:created_at:asc',
                  label: 'Data de criação: Crescente',
                },
                {
                  value: 'assets:size:asc',
                  label: 'Tamanho: Crescente',
                },
                {
                  value: 'assets:size:desc',
                  label: 'Tamanho: Decrescente',
                },
              ]"
            />
          </v-flex>
        </v-layout>
        <v-btn v-if="!user.data.restricted" fab to="/fl/upload" color="primary uploadBtn">
          <v-icon color="white"> mdi-cloud-upload </v-icon>
        </v-btn>
      </div>

      <ais-hits>
        <template v-slot="{ items }">
          <v-row class="mt-8">
            <v-col
              v-for="(file, i) in items"
              :key="i"
              sm="6"
              md="4"
              lg="3"
              xl="2"
            >
              <FileCard :snapshot="file" @file-deleted="handleDeletedFile" />
            </v-col>
          </v-row>
        </template>
      </ais-hits>

      <ais-pagination :total-pages="10" />
    </ais-instant-search>
  </v-app>
</template>

<script>
import FileCard from "../../Cards/FileCard";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { mapGetters } from 'vuex'

export default {
  name: "FileList",
  components: {
    FileCard,
  },

  data: () => ({
    filterRules: [(v) => !!v || "Campo obrigatório"],
    filterByDate: false,
    minNumber: null,
    min: null,
    max: null,
    errorMsg: "",
    disabledBtn: true,
    maxNumber: null,
    filterByType: false,
    searchClient: instantMeiliSearch(
      sessionStorage.getItem("url"),
      sessionStorage.getItem("token")
    ),
  }),

  watch: {
    minNumber() {
      if (this.minNumber.length === 10) {
        this.toTimeStamp(this.minNumber).then((res) => {
          this.min = res;
        });
      }
    },
    maxNumber() {
      if (this.maxNumber.length === 10) {
        this.toTimeStamp(this.maxNumber).then((res) => {
          this.max = res;
          if (this.max > this.min) {
            this.disabledBtn = false;
            this.errorMsg = "";
          } else {
            this.disabledBtn = true;
            this.errorMsg = "Data final é maior que inicial";
          }
        });

        this.disabledBtn = false;
        this.errorMsg = "";
      }
    },
  },
  methods: {
    async toTimeStamp(myDate) {
      myDate = myDate.split("/");
      const dt = new Date(myDate[2], myDate[1] - 1, myDate[0]).getTime();
      let parsed = parseInt(dt / 1000);

      return parsed;
    },

    handleDeletedFile(item) {
      const index = this.files.indexOf(item);
      this.files.splice(index, 1);
    },
  },

  computed: {
    ...mapGetters({
    user: "user",
    }),
  },
};
</script>

<style scoped>
.fields {
  width: 400px;
  height: 40px;
  text-indent: 15px;
  border-radius: 10px;
}
.ais {
  color: rgb(127, 127, 133) !important;
}

input:focus {
  outline: none;
  border: 1px solid #0f8fef;
}
.uploadBtn {
  position: absolute;
  right: 0;
  top: 0;
}
.active {
  background: rgb(244, 244, 245) !important;
  color: rgb(120, 120, 122) !important;
}
.filter {
  padding: 10px;
  color: rgb(127, 127, 133) !important;
  border-radius: 9px;
}
.input-range {
  color: black !important;
}
input[type="number"] {
  padding: 0px;
  border: 0;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.03);
  border: 1px solid grey;
  font-family: inter !important;
  font-weight: 400;
  font-size: 16px;
  height: 35px;
  margin-top: 2px;
}
.range-filter {
  width: 200px;
  border-radius: 10px;
  margin-left: 15px;
}
</style>
