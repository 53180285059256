import Vue from "vue";

const components = {
  // Table
  SimpleTable: () => import("@/components/Table/SimpleTable"),

  // Inputs
  TextField: () => import("@/components/Inputs/TextField"),
  SelectField: () => import("@/components/Inputs/SelectField"),
  ComboBox: () => import("@/components/Inputs/ComboBox"),
  AutoComplete: () => import("@/components/Inputs/AutoComplete"),

  // Buttons
  Button: () => import("@/components/Button/Button"),

  // Loader
  Skeleton: () => import("@/components/Skeleton/CardLoader"),

  // Tooptip

  Tooltip: () => import("@/components/Tooltip"),
  Alert: () => import("@/components/Skeleton/Alert"),
};

Object.entries(components).forEach(([name, component]) =>
  Vue.component(name, component)
);
