<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="show" max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Adicionar Enunciado</span>
      </v-card-title>

      <v-card-text>
        <v-select v-model="type" label="Tipo" :items="types" outlined>
        </v-select>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.stop="show = false"
          >Cancelar</v-btn
        >
        <v-btn color="blue darken-1" text @click="save">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CardTitleDialog",

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: Object,
      default: null,
    },
  },

  computed: {
    show: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  data: () => ({
    loading: true,
    types: ["VIDEO", "AUDIO", "IMAGE", "TEXT", "LOTTIE", "REF"],
    type: "AUDIO",
  }),

  watch: {
    showDialog(val) {
      this.show = val;
    },
    title(title) {
      if (title) this.type = title.type;
    },
  },

  methods: {
    save() {
      this.$emit("add-title", {
        id: Math.floor(Math.random() * 1000),
        type: this.type,
        description: null,
        required: false,
      });

      this.type = "AUDIO";

      this.show = false;
    },
  },
};
</script>

<style scoped></style>
