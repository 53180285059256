<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="700" persistent v-if="dialogData">
      <v-card width="700" height="600px">
        <v-card-title class="large bold">
          Escolha em qual planeta a questão será
          <span v-if="action === 'Clonando Questão'" class="ml-2">
            clonada</span
          >
          <span v-else class="ml-2"> transferida</span>

          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-subtitle class="pb-10 pt-1 normal">
          <span class="bold normal">Questão:</span>
          {{ dialogData.ToBeCloned.description }}
        </v-card-subtitle>
        <v-virtual-scroll
          :bench="planets.lenght"
          :items="planets"
          height="470"
          item-height="90"
        >
          <template v-slot:default="{ item }">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="medium-text bold">
                  {{
                    item.title ? item.title : "Título não definido"
                  }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  <span class="bold">Domain: </span>{{ item.domain_code }} |
                  <span class="bold"> Status: </span>
                  {{ item.status ? item.status : "Status não definido" }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  LEVEL: {{ item.level }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  class="primary text-capitalize"
                  :to="{
                    name: 'da-question-edit',
                    params: {
                      action: action,
                      planetId: item.id,
                      id: dialogData.ToBeCloned.id,
                      planeta: dialogData.Planet,
                    },
                  }"
                  >Selecionar</v-btn
                >
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import firebase from "firebase";
export default {
  props: ["dialogData", "action", "dialog", "domain"],
  data() {
    return {
      copiado: false,
      planets: [],
    };
  },

  methods: {
    closeDialog() {
      this.$emit("close");
    },
    async loadPlanets() {
      const db = firebase.firestore();

      await db
        .collection("bundles")
        .where("domain_code", "==", this.domain)
        .orderBy("title", "asc")
        .get()
        .then((querySnapshot) => {
          this.planets = [];
          querySnapshot.docs.forEach((doc) => {
            this.planets.push(doc.data());
          });
        });
      this.loading = false;
    },
  },
  created() {
    this.loadPlanets();
  },
};
</script>
<style scoped>
.imagemodal {
  width: 500px !important;
  height: 500px !important;
  object-fit: scale-down !important;
}
.copy {
  cursor: pointer;
}
.copy-text {
  color: green !important;
}
.preview {
  height: 500px;
}
</style>
