<template>
  <v-app class="transparent">
    <ais-instant-search :search-client="searchClient" index-name="questions">
      <!-- HEADER / FILTROS -->
      <div class="text-center">
        <ais-autocomplete>
          <template v-slot="{ currentRefinement, refine }">
            <input
              type="search"
              placeholder="Buscar arquivos"
              class="fields"
              :value="currentRefinement"
              @input="refine($event.currentTarget.value)"
            />
          </template>
        </ais-autocomplete>
        <Button
          class="mt-1"
          large
          to="qt/create"
          icon="bi bi-plus-circle"
          tipo="primary newQuestion"
          label="Novo"
        />

        <ais-refinement-list attribute="axis_id" searchable show-more>
          <template v-slot="{ items, refine, createURL }">
            <v-layout wrap class="list-wrapper mt-10">
              <v-flex
                xs2
                class="list"
                v-for="item in items"
                :key="item.value"
                @click.prevent="refine(item.value)"
              >
                <a
                  class="normal bold"
                  :href="createURL(item)"
                  :style="{ fontWeight: item.isRefined ? 'bold' : '' }"
                >
                  {{ getEixoName(item) }}

                  <span class="thin normal">
                    ({{ item.count.toLocaleString() }})
                  </span>
                </a>
              </v-flex>
            </v-layout>
          </template>
        </ais-refinement-list>

        <ais-stats class="ma-3 mt-5 normal thin">
          <template v-slot="{ nbHits }">
            <span class="normal gray-300">
              {{ nbHits }} Resultados encontrados.
            </span>
          </template>
        </ais-stats>
      </div>
      <!-- Resultado -->
      <ais-hits>
        <template v-slot="{ items }">
          <SimpleTable
            :headers="headers"
            :items="items"
            :axis="axis"
            @ActionBtn="open"
            sortable
          />
        </template>
      </ais-hits>

      <ais-configure
        :filters="`domain=${this.$route.params.domain}`"
        :attributesToSnippet="['description:50']"
        snippetEllipsisText="…"
        :hits-per-page.camel="20"
      />

      <ais-pagination :total-pages="10" />
    </ais-instant-search>
  </v-app>
</template>

<script>
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
export default {
  name: "QuestionList",
  data: () => ({
    searchClient: instantMeiliSearch(
      sessionStorage.getItem("url"),
      sessionStorage.getItem("token")
    ),
    headers: [
      {
        text: "Título",
        align: "center",
        sortable: false,
        value: "title",
        width: "20%",
      },
      {
        text: "Eixo",
        width: "13%",
        align: "center",
        value: "axis_id",
        sortable: false,
      },
      {
        text: "Nível",
        width: "13%",
        align: "center",
        value: "level",
        sortable: false,
      },
      {
        text: "Modelo",
        width: "13%",
        align: "center",
        value: "model_id",
        sortable: false,
      },
      {
        text: "Status",
        width: "13%",
        align: "center",
        value: "status",
        sortable: false,
      },
      {
        text: "Atualizado em",
        width: "13%",
        align: "center",
        value: "updated",
        sortable: false,
      },
      {
        text: "",
        align: "center",
        width: "13%",
        value: "action",
        sortable: false,
      },
    ],
    axis: [],
    questions: [],
  }),

  computed: {
    domain() {
      return this.$route.params.domain.toUpperCase();
    },
  },

  methods: {
    getEixoName(id) {
      let name = null;
      for (let i = 0; i < this.axis.length; i++) {
        if (id.highlighted === this.axis[i].id) {
          name = this.axis[i].name;
        }
      }
      return name;
    },

    getAxis() {
      return this.$database
        .collection("axis")
        .where("domain_code", "==", this.domain)
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((doc) => {
            const data = doc.data();
            this.axis.push(data);
          });
        });
    },

    open(item) {
      this.$router.push({ name: "question-edit", params: { id: item.id } });
    },
  },
  mounted() {
    this.getAxis();
  },
};
</script>

<style scoped>
input:focus {
  outline: none;
  border: 1px solid #0f8fef;
}
.newQuestion {
  position: absolute;
  top: 0;
  right: 0;
}
.normal {
  color: rgb(69, 88, 116) !important;
}
</style>
