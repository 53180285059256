<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container>
    <v-card :loading="loading" outlined shaped>
      <v-card-title class="large">
        {{ cardTitle }}
        <v-spacer></v-spacer>
        <div v-if="model.updated_at" class="medium-text light">
          Atualizado em: {{ model.updated_at | formatDate }}
        </div>
      </v-card-title>
      <v-row>
        <v-col cols="12">
          <v-card-text>
            <span class="normal bold text-uppercase"> Geral </span>
            <v-row>
              <v-col cols="3">
                <VueFileAgent
                  v-model="file"
                  ref="vueFileAgent"
                  maxSize="15MB"
                  helpText="Selecione arquivos ou arraste e solte aqui"
                  :multiple="false"
                  :deletable="true"
                  :meta="false"
                  :accept="'image/*'"
                  :compact="true"
                  :errorText="{
                    type: 'Arquivo de extensão não aceita. Apenas imagem é aceita.',
                    size: 'Arquivos não devem ultrapassar 15MB',
                  }"
                  style="background-color: gray"
                  @beforedelete="file = null"
                >
                </VueFileAgent>
              </v-col>
              <v-col cols="9">
                <v-form ref="form">
                  <v-row>
                    <TextField
                      cols="3"
                      :model="model.id"
                      @input="model.id = $event"
                      label="Código"
                      :disabled="id != null ? true : false"
                      rules="true"
                    />

                    <TextField
                      cols="3"
                      :model="model.app_code_version"
                      @input="model.app_code_version = $event"
                      label="Versão mínima"
                      :disabled="id != null ? true : false"
                      rules="true"
                    />

                    <SelectField
                      cols="3"
                      :model="model.orientation"
                      @input="model.orientation = $event"
                      :fields="Orientation"
                    />

                    <SelectField
                      cols="3"
                      :model="model.status"
                      @input="model.status = $event"
                      :fields="Status"
                    />
                  </v-row>
                  <v-row>
                    <v-textarea
                      v-model="model.description"
                      label="Descrição"
                      outlined
                      class="pr-4 pl-4"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                    />

                    <ComboBox
                      cols="12"
                      :model="model.tags"
                      @input="model.tags = $event"
                      :fields="Tags"
                      rules="true"
                    />

                    <TextField
                      cols="6"
                      :model="model.doc_link"
                      @input="model.doc_link = $event"
                      label="Documentação"
                      rules="true"
                    />
                    <TextField
                      cols="6"
                      :model="model.video_link"
                      @input="model.video_link = $event"
                      label="Vídeo"
                      rules="true"
                    />
                  </v-row>
                </v-form>
              </v-col>
            </v-row>

            <v-card outlined>
              <div class="pa-2 d-flex justify-space-between">
                <span class="text-overline"> Enunciados </span>
                <v-btn
                  fab
                  depressed
                  small
                  color="primary"
                  @click.stop="showTitleDialog = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">+</th>
                      <th class="text-left">TIPO</th>
                      <th class="text-left">Descrição</th>
                      <th class="text-left">Obrigatório</th>
                      <th class="text-right">Ações</th>
                    </tr>
                  </thead>
                  <draggable
                    v-model="model.titles"
                    tag="tbody"
                    class="list-group"
                    ghost-class="ghost"
                    handle=".handle"
                  >
                    <tr
                      v-for="item in model.titles"
                      :key="item.position"
                      class="list-group-item"
                    >
                      <td style="width: 0.1%">
                        <v-btn style="cursor: move" icon class="handle">
                          <v-icon>mdi-drag-horizontal</v-icon>
                        </v-btn>
                      </td>
                      <td>{{ item.type }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ item.required }}</td>
                      <td style="width: 1%">
                        <v-row>
                          <v-btn
                            small
                            text
                            icon
                            @click.stop="callEditTitleDialog(item)"
                          >
                            <v-icon small> mdi-pencil </v-icon>
                          </v-btn>
                          <v-btn small text icon @click="deleteTitle(item)">
                            <v-icon small> mdi-delete </v-icon>
                          </v-btn>
                        </v-row>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-simple-table>
            </v-card>

            <v-card outlined class="mt-5">
              <div class="pa-2 d-flex justify-space-between">
                <span class="text-overline"> Regras </span>
                <v-btn
                  fab
                  depressed
                  small
                  color="primary"
                  @click.stop="showRuleDialog = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">+</th>
                      <th class="text-left">TIPO</th>
                      <th class="text-left">Nome</th>
                      <th class="text-left">Valor Padrão</th>
                      <th class="text-right">Ações</th>
                    </tr>
                  </thead>
                  <draggable
                    v-model="model.rules"
                    tag="tbody"
                    class="list-group"
                    ghost-class="ghost"
                    handle=".handle"
                  >
                    <tr
                      v-for="item in model.rules"
                      :key="item.position"
                      class="list-group-item"
                    >
                      <td style="width: 0.1%">
                        <v-btn style="cursor: move" icon class="handle">
                          <v-icon>mdi-drag-horizontal</v-icon>
                        </v-btn>
                      </td>
                      <td>{{ item.type }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.value }}</td>
                      <td style="width: 1%">
                        <v-row>
                          <v-btn
                            small
                            text
                            icon
                            @click.stop="callEditRuleDialog(item)"
                          >
                            <v-icon small> mdi-pencil </v-icon>
                          </v-btn>
                          <v-btn small text icon @click="deleteRule(item)">
                            <v-icon small> mdi-delete </v-icon>
                          </v-btn>
                        </v-row>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-simple-table>
            </v-card>
          </v-card-text>
        </v-col>
        <!--<v-col cols="4">-->
        <!--<v-card-text>-->
        <!--<span class="text-overline">-->
        <!--Questões-->
        <!--</span>-->
        <!--</v-card-text>-->
        <!--</v-col>-->
      </v-row>

      <CardTitleDialog
        @create-title="createTitle"
        @update-title="updateTitle"
        :showDialog="showTitleDialog"
        :title="titleSelected"
        v-model="showTitleDialog"
      >
      </CardTitleDialog>

      <CardRuleDialog
        @create-rule="createRule"
        @update-rule="updateRule"
        :showDialog="showRuleDialog"
        :rule="ruleSelected"
        v-model="showRuleDialog"
      >
      </CardRuleDialog>

      <div>
        <v-col class="d-flex justify-end">
          <Button
            v-if="id != null"
            label="Deletar"
            :loading="saving"
            :disabled="saving"
            @ClickEvent="deleteModel"
            tipo="error"
            class="mr-4"
          />
          <Button
            label="Salvar"
            :loading="saving"
            :disabled="saving"
            @ClickEvent="save"
            tipo="primary"
          />
        </v-col>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";
import Vue from "vue";
import CardTitleDialog from "../../components/Dialogs/CardTitleDialog";
import CardRuleDialog from "../../components/Dialogs/CardRuleDialog";
import draggable from "vuedraggable";
import firebase from "firebase";

Vue.use(VueFileAgentStyles);

export default {
  name: "ModelCreate",

  components: { CardRuleDialog, CardTitleDialog, draggable },

  props: {
    id: {
      type: String,
      default: null,
    },
  },

  computed: {
    cardTitle() {
      return this.id == null ? "Novo Modelo" : "Editar Modelo";
    },
  },

  data: () => ({
    loading: true,
    saving: false,

    Tags: {
      label: "Tags",
      answers: ["Português", "Matemática", "Vertical", "Horizontal"],
      multiple: "multiple",
      chips: true,
      rules: true,
    },
    Status: {
      label: "Status",
      answers: ["RASCUNHO", "DESENVOLVIMENTO", "PRODUÇÃO", "ARQUIVADO"],
      rules: true,
    },
    Orientation: {
      label: "Orientação",
      answers: ["PORTRAIT", "LANDSCAPE"],
      rules: true,
    },
    showTitleDialog: false,
    titleSelected: null,
    showRuleDialog: false,
    ruleSelected: null,
    file: null,

    model: {
      id: null,
      app_code_version: 47,
      file: null,
      description: null,
      question_count: 0,
      rules: [
        // {
        //     default: 'false',
        //     type: 'boolean',
        //     name: "autoplay",
        // },
      ],
      titles: [
        // {
        //     position: 0,
        //     type: 'IMAGE',
        //     description: "Imagem principal",
        //     required: false,
        // },
      ],
      tags: ["Matemática", "Horizontal"],
      orientation: "LANDSCAPE",
      status: "RASCUNHO",
      doc_link: null,
      video_link: null,
      updated_at: null,
    },
  }),

  methods: {
    remove(item) {
      this.model.tags.splice(this.model.tags.indexOf(item), 1);
      this.model.tags = [...this.model.tags];
    },
    //Titles
    createTitle(title) {
      this.model.titles.push(title);
    },
    updateTitle(title) {
      const index = this.titles.indexOf(this.titleSelected);
      this.model.titles[index] = title;
      this.titleSelected = null;
    },
    callEditTitleDialog(title) {
      this.titleSelected = title;
      this.showTitleDialog = true;
    },
    deleteTitle(title) {
      const index = this.model.titles.indexOf(title);
      this.model.titles.splice(index, 1);
    },
    //Rules
    createRule(rule) {
      this.model.rules.push(rule);
    },
    updateRule(rule) {
      const index = this.model.rules.indexOf(this.ruleSelected);
      this.model.rules[index] = rule;
      this.ruleSelected = null;
    },
    callEditRuleDialog(rule) {
      this.ruleSelected = rule;
      this.showRuleDialog = true;
    },
    deleteRule(rule) {
      const index = this.model.rules.indexOf(rule);
      this.model.rules.splice(index, 1);
    },

    uploadImage(docRef) {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`models/${this.model.id}`);
      const uploadTask = fileRef.put(this.file.file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.file.progress(progress);
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          docRef.update({
            image: uploadTask.snapshot.ref.fullPath,
          });
          this.$router.push({ name: "model-list" });
        }
      );
    },

    save() {
      if (this.$refs.form.validate()) {
        this.saving = true;

        this.model.updated_at = firebase.firestore.FieldValue.serverTimestamp();
        this.model.id = this.model.id.toUpperCase();

        let docRef = this.$database.collection("models").doc(this.model.id);
        docRef
          .set(this.model)
          .then(() => {
            if (this.file.file) this.uploadImage(docRef);
            else this.$router.push({ name: "model-list" });
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
            this.saving = false;
          });
      }
    },

    deleteModel() {
      if (confirm("Meu Deus! 😱 Não tem volta!!! Tem certeza?")) {
        this.saving = true;

        let docRef = this.$database.collection("models").doc(this.model.id);
        docRef.delete().then(() => {
          this.$router.push({ name: "model-list" });
        });
      }
    },
  },

  created() {
    if (this.id) {
      let docRef = this.$database.collection("models").doc(this.id);
      docRef.get().then((doc) => {
        if (doc.exists) {
          this.model = doc.data();

          const storageRef = firebase.storage().ref();
          const fileRef = storageRef.child(`models/${this.model.id}`);

          fileRef
            .getMetadata()
            .then((metadata) => {
              fileRef.getDownloadURL().then((url) => {
                this.file = {
                  name: metadata.name,
                  type: metadata.contentType,
                  size: metadata.size,
                  url: url,
                };
              });
            })
            .catch(function (error) {
              // Uh-oh, an error occurred!
              console.log(error);
            });

          this.loading = false;
        } else {
          this.$router.push({ name: "model-list" });
        }
      });
    } else {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
