<template>
  <v-card outlined class="pa-4 mb-2">
    <slot></slot>
    <v-row>
      <TextField
        label="Tipo"
        :model="rule.type"
        @input="rule.type = $event"
        disabled
        cols="3"
        rules="true"
      />

      <TextField
        cols="3"
        :model="rule.name"
        @input="rule.name = $event"
        label="Nome"
        disabled
        rules="true"
      />
      <TextField
        cols="6"
        :model="rule.value"
        @input="rule.value = $event"
        label="Valor"
        hint="Atentar para o tipo desta regra."
        rules="true"
      />
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "RuleForm",

  props: {
    rule: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
